// Package imports:
import React from 'react';
// Component imports:
import AllExchangeNoticesPage from '../../components/ExchangeNotices/AllExchangeNotices';
import Entrypoint from '../Entrypoint';

const AllExchangeNotices: React.FC = () => {
    return (
        <Entrypoint>
            <AllExchangeNoticesPage />
        </Entrypoint>
    );
}

export default AllExchangeNotices;