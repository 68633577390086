// Package imports:
import React from 'react';
// Component imporst:
import Hreyfingar from '../../components/Hreyfingar/Hreyfingar';
import Entrypoint from '../Entrypoint';

const InvoicesPage: React.FC = () => {
    return (
        <Entrypoint>
            <Hreyfingar />
        </Entrypoint>
    );
}

export default InvoicesPage;