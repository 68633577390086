// Package imports:
import React from "react";
// Component imports:
import Entrypoint from "../Entrypoint";
import PEPSearchPage from "../../components/PEP/PepPage";
import { Subscription } from "../../types/KeldanTypes";
import { HistoryRefreshProvider } from "../../contexts/HistoryRefreshContext";

interface IProps {
  isAuthenticated: boolean;
  subscriptions: Subscription[];
}

const Search: React.FC<IProps> = ({ isAuthenticated, subscriptions }) => {
  return (
    <Entrypoint>
      <HistoryRefreshProvider>
        <PEPSearchPage
          isAuthenticated={isAuthenticated}
          subscriptions={subscriptions}
        />
      </HistoryRefreshProvider>
    </Entrypoint>
  );
};

export default Search;
