// Package imports:
import React from 'react';
// Component imports:
import ExchangeNoticesBySymbolPage from '../../components/ExchangeNotices/ExchangeNoticesBySymbol';
import Entrypoint from '../Entrypoint';

interface IProps {
    id: string;
}

const ExchangeNoticesBySymbol: React.FC<IProps> = ({
    id
}) => {
    return (
        <Entrypoint>
            <ExchangeNoticesBySymbolPage id={id} />
        </Entrypoint>
    );
}

export default ExchangeNoticesBySymbol;