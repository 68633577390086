// Type imports:
import { OwnedReport } from "./KeldanTypes"

export interface MyUserInfo {
    email: string | null,
    username: string | null,
    fullname: string | null,
    isSyncedKodi: boolean | null,
    msisdn: string | null,
    isActive: boolean | null,
}

export interface SettingsResponse {
    customerAddress: string | null,
    customerZip: string | null,
    customerSsnLegit: boolean | null,
    customerCity: string | null,
    customerBonds: boolean | null,
    customerName: string | null,
    customerPhoneNumber: string | null,
    customerPlan: number | null,
    customerSsn: string | null,
    cardUsable: boolean | null,
    customerEmail: string | null,
    customerBondsExplain: string | null,
    customerIsCardPayable: boolean | null,
    customerChargeSubscriptionDate: {
        date: number | null,
        month: number | null,
        year: number | null
    }
}


//MIGHT DELETE ?? 
export interface MyUsersViewModel {
    users: Array<KeldanUser> | null,
    isAdmin: boolean | null,
    id: number | null,
    fullname: string
    username: string | null,
    email: string | null,
    password: string | null,
    passwordAgain: string | null,
    category: string | null,
    active: boolean | null,
    bonds: boolean | null,
    msisdn: string | null,
    accountexpires: Date | null,
    error: string | null,
    historyList: HistoryList,
}

export interface HistoryList {
    productName: string | null,
    count: number | null,
    events: HistoryEvent[],
}

export interface HistoryEvent {
    id: number | null,
    eventDate: string | null,
    type: string | null,
    reference: string | null,
    fullname: string | null,
    typeName: string | null,
    typeInstance: string | null,
    searchTerm: string | null,
    fileId?: string | null
}

export interface KeldanUser {
    id: number | null,
    username: string | null,
    fullname: string | null,
    ssn: string | null,
    email: string | null,
    regDate: string | null,
    active: boolean | null,
    enabled: boolean | null,
    accountExpires: string | null,
    locked: boolean | null,
    msisdn: string | null,
    admin: boolean | null,
    bondsAllowed: boolean | null,
    assetsAllowed: boolean | null,
    mustUseReference: boolean | null
    category: string | null,
}


export interface MySettingsViewModel extends MyKeldaViewModel {
    settingsResponse: SettingsResponse | null,
    myUserInfo: MyUserInfo | null,
    card: CreditCardResponse | null,
    cardNumber: string | null,
    cardName: string | null,
    expiration: string | null,
    cvc: string | null,
    discountCode: string | null,
    isAdmin: boolean,
}

export interface CreditCardResponse {
    error: string | null,
    brand: string | null,
    comment: string | null,
    active: boolean | null,
    expDate: string | Date | null,
    regDate: string | Date | null,
    lastFour: string | null,
    statusCode: string | null,
    success: string | null,
}

export interface IMyKeldaSettingsResponse {
    saved?: boolean,
    discount?: boolean,
    info?: string,
    chargeDate?: string
}

export interface MyCardViewModel {
    card: CreditCardResponse | null,
    cardNumber: string | null,
    cardName: string | null,
    expiration: string | null,
    cvc: string | null,
    errors: string[] | null,
}

export interface MyKeldaViewModel {
    historyList: HistoryList;
}

//Vaktin Models

export interface WatchType {
    name: string,
    description: string,
    abbrev: string,
    listCount: number | null, 
}

export interface Watch {
    id: number,
    added: string,
    lastModified: string | null,
    watchMe: string,
    name: string | null,
    active:boolean,
    deactiveDate: string | null,
    type: WatchType,
}
export interface IDiscountCodeResponse {
    codeExists: boolean | null,
    codeValid: boolean | null,
    customerHasUsedCode: boolean | null
}

export interface MasterSearchViewModel {
    historyList: HistoryList;
    reference: string;
}

export interface MyReportsSearchViewModel extends MasterSearchViewModel {
    term: string,
    reports: HistoryList,
    result: OwnedReport[],
    id: string,
}


/**
 * INVOICES
 */

export interface IKeldanApiAllInvoicesModel {
    invoiceContainers: IInvoiceContainer[] | null;
    error: string | null;
}

export interface IInvoiceContainer {
    invoice: IInvoice
}

export interface IInvoice {
    id: number,
    invoiceDate: string | null,
    amount: number,
    amountVat: number,
    amountTotal: number,
    chargeDate: string | null,
    fromDate: string,
    toDate: string,
    valid: boolean
    onError: boolean
    invoiceType: IInvoiceType | null
    creditInvoiceId: number | null
    description: string | null
}

export interface IInvoiceType {
    id: number,
    name: string | null,
    active: boolean
}

export interface IInvoiceUser {
    id: number | null,
    userName: string,
    userId: number | null,
    invoiceDate: Date,
    fromDate: Date | null,
    toDate: Date | null,
    amount: number | null, 
    amountVat: number | null,
    amountTotal: number | null,
}

export interface IInvoiceReference {
    invoiceLines: IInvoiceLine[],
    ref: string | null,
    total: number | null,
    totalWithVat: number | null,
    vat: number | null,
}

export interface IInvoiceLine {
    id?: number | null,
    regDate: Date,
    amount: number,
    amountVat: number,
    amountTotal: number,
    description: string,
    volume: number,
    discount: number,
    eventDate: string,
    eventId: number,
    eventRef: string,
    searchTerm: string,
}

export type InvoiceModalInfo = {
    modalType: 'Hreyfingar',
    invoice: IInvoice
} | {
    modalType: 'Notendur',
    invoiceUser: IInvoiceUser
} | {
    modalType: 'Tilvisun',
    invoiceReference: IInvoiceReference
}

export interface IInvoiceUserDetail {
    name: string
    count: number
    sumAmountTotal: number
    sumAmount: number
    sumAmountVat: number
    amountPer: number
    invoiceLine: IInvoiceUserDetailLine[]
}

export interface IInvoiceUserDetailLine
{
    id: number | null
    regDate: string | null;
    amount: number | null;
    amountVat: number | null;
    amountTotal: number | null;
    description: string | null;
    volume: number | null;
    discount: number | null;
    eventDate: string | null;
    eventId: number | null;
    eventRef: string | null;
    searchTerm: string | null;
}

export interface IInvoiceCustomer {
    ssn: string | null;
    name: string | null;
    name2: string | null;
    address: string | null;
    address2: string | null;
    zipCode: string | null;
    city: string | null;
    country: string | null;
}

export interface IInvoiceLinesResult {
    name: string;
    count: number;
    sumAmountTotal: number;
    sumAmount: number;
    sumAmountVat: number;
    amountPer: number;
    invoiceline: IInvoiceLine[] | null;
    invoiceDate: string;
    from: string;
    to: string;
    invoiceNr: number
}

// Weird tuple thing. also if bakfærsla, returns single invoice
export type InvoiceCustomerResult = [IInvoiceCustomer, IInvoiceLinesResult, {}, IInvoiceLinesResult[]] | IInvoice

// Invoice storage types.

export type InvoiceIdAndUserIdToIInvoiceUserDetailsMap = {
    [T in string]: IInvoiceUserDetail[] | undefined | Error;
}

export type InvoiceIdToInvoiceCustomerResult = {
    [T in number]: InvoiceCustomerResult | undefined | Error;
}

export const getUserDetailsMapId = (invoiceUser: IInvoiceUser) => `${invoiceUser.id};${invoiceUser.userId}`;