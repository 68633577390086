// Package imports:
import React, { useRef, useState } from "react";
import Bugsnag from "@bugsnag/js";
// Component imports:
import Button from "../../ui-elements/Button/Button";
import Link from "../../ui-elements/Link/Link";
import Alert from "../../ui-elements/Alert/Alert";
import Table from "../../ui-elements/Table/Table";
import Loading from "../../ui-elements/Loading/Loading";
import DisplayBox from "../../ui-elements/DisplayBox/DisplayBox";
import HeadSection from "../../ui-elements/HeadSection/HeadSection";
import SmallSearch from "../SmallSearch/SmallSearch";
import BulletList from "../../ui-elements/Lists/BulletList";
import SearchResults from "../../ui-elements/SearchResults/SearchResults";
// Service imports:
import { getEventUrl } from "../../services/utils";
import { GET_KELDAN_API_URL } from "../../services/config";
import { ErrorMessages } from "../../services/errorMessages";
// Type imports:
import { MyReportsSearchViewModel } from "../../types/MyKeldaTypes";
import { OwnedReport } from "../../types/KeldanTypes";

const ReportsPage: React.FC = () => {
  const [searchResultTerm, setSearchResultTerm] = useState<string>("");
  const [searchResults, setSearchResults] = useState<OwnedReport[] | null>();

  const searchResultsRef = useRef<HTMLDivElement>(null);

  const handleSubmit = async (term: string) => {
    setSearchResults(null);
    try {
      const requestQuery = new URLSearchParams({
        term: term,
      });
      const url = `${GET_KELDAN_API_URL()}/MyKelda/ReportsSearch`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: requestQuery,
      });
      if (response.ok) {
        if (response.redirected) {
          window.location.href = response.url;
        } else {
          const responseBody: MyReportsSearchViewModel = await response.json();
          setSearchResults(responseBody.result);
          searchResultsRef.current?.scrollIntoView();
        }
      } else {
        throw new Error(ErrorMessages.RequestFailed);
      }
    } catch (e) {
      if (e instanceof Error) Bugsnag.notify(e);
    }
  };

  const displaySearchResults = () => {
    if (searchResults === undefined || searchResults === null) {
      return <Loading />;
    }
    if (searchResults.length > 0) {
      return (
        <Table
          tableSize="lg"
          data={searchResults}
          columns={[
            {
              title: "Dags. pöntunar",
              renderCell: ({ date }) => date.replace(/\//g, "."),
              textAlign: "left",
              simpleSortable: ({ date }) => date,
            },
            {
              title: "Tegund",
              renderCell: ({ typeName }) => typeName,
              textAlign: "left",
              simpleSortable: ({ typeName }) => typeName,
            },
            {
              title: "Ár",
              renderCell: ({ yearValid }) => yearValid,
              textAlign: "left",
              simpleSortable: ({ yearValid }) => yearValid,
            },
            {
              title: "Tilvísun",
              renderCell: ({ reference }) => reference,
              textAlign: "left",
              simpleSortable: ({ reference }) => reference,
            },
            {
              title: "Leitarorð",
              renderCell: ({ value }) => value,
              textAlign: "left",
              simpleSortable: ({ value }) => value,
            },
            {
              title: "",
              renderCell: ({ value, typeName, balls, fileId }) => (
                <Button
                  anchorProps={{
                    href: `${getEventUrl(value, typeName, balls, fileId)}`,
                    target: "_blank",
                  }}
                  size="sm"
                  buttonType="secondary"
                >
                  Skoða
                </Button>
              ),
            },
          ]}
        />
      );
    }
    return <Alert type="info" headText={"Engar niðurstöður fundust fyrir " + searchResultTerm} />;
  };

  interface LinkItem {
    label: string;
    id: string;
  }

  const links: LinkItem[] = [
    { label: "Fyrirtæki", id: "Fyrirtaeki" },
    { label: "PEP uppflettingar", id: "PEP" },
    { label: "Alþjóðlegir válistar - einstaklingar", id: "Althjodlegir-Valistar-Einstaklingar" },
    { label: "Alþjóðlegir válistar - lögaðilar", id: "Althjodlegir-Valistar-Logadilar" },
    { label: "Samanburður", id: "Samanburdur" },
    { label: "Fasteignir", id: "Fasteignir" },
    { label: "Listar", id: "Listar" },
    { label: "Vinnuvélar", id: "Vinnuvelar" },
    { label: "Fyrirmyndarfyrirtæki", id: "Fyrirmyndarfyrirtaeki" },
    { label: "Lögbirtingar", id: "Logbirtingar" },
    { label: "Þjóðskrá", id: "Thjodskra" },
    { label: "Hlutafélög", id: "Hlutafelog" },
    { label: "Reikningar", id: "Reikningar" },
    { label: "Ökutæki", id: "Okutaeki" },
    { label: "Hlutafélagaþátttaka", id: "Hlutafelagathatttaka" },
    { label: "Þinglýst skjöl", id: "Thinglyst-Skjol" },
  ];

  return (
    <div className="main KCL_reportsPage">
      <div className="section section_upper">
        <div className="section_upper-body">
          <div className="shell">
            <HeadSection title="Keyptar skýrslur" showRecentReports />
            <SmallSearch
              search={searchResultTerm}
              setSearch={setSearchResultTerm}
              onSubmit={handleSubmit}
              placeHolder="Leitaðu eftir tegund skýrslu, upprunalegum leitarorðum, tilvísun reiknings og fleira..."
            />
          </div>
          <SearchResults
            title={`Leitarniðurstöður fyrir ${searchResultTerm}`}
            show={searchResults !== undefined}
            closeResults={() => setSearchResults(undefined)}
            ref={searchResultsRef}
            component={<DisplayBox>{displaySearchResults()}</DisplayBox>}
          />
        </div>
      </div>

      <div className="section section_lower">
        <div className="section__lower-body">
          <div className="shell">
            <div className="row list-title">
              <h2>Flokkar</h2>
            </div>
            <BulletList
              items={[...links]
                .sort((a, b) => a.label.localeCompare(b.label))
                .map((item) => (
                  <Link linkSize="18" url={`/Min-Kelda/Keyptar-Skyrslur/${item.id}`} key={item.id}>
                    {item.label}
                  </Link>
                ))}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportsPage;
