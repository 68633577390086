// Package imports:
import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
// Component imports:
import Link from '../../../ui-elements/Link/Link';
import LockIcon from '../../../ui-elements/LockIcon/LockIcon';
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Censored from '../../../ui-elements/Censored/Censored';
import Tabs from '../../../ui-elements/Tabs/Tabs';
import SubscriptionBanner from '../../SubscriptionBanner/SubscriptionBanner';
import Loading from '../../../ui-elements/Loading/Loading';
import ErrorAlert from '../../ErrorAlert/ErrorAlert';
import Table, { IColumn } from '../../../ui-elements/Table/Table';
import SmallColumnChart from '../../../ui-elements/SmallColumnChart/SmallColumnChart';
import Tooltip from '../../../ui-elements/Tooltip/Tooltip';
import Label from '../../../ui-elements/Label/Label';
// Service imports:
import { convertToPercentage, formatNumber, getTableDate } from '../../../services/utils';
import { useFetchedApiLmdData } from '../../../services/apiHooks';
import { GET_API_LMD_URL } from '../../../services/config';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { CompanyReportInfo, ICompanyViewModel, ICompanyFinancialRatios } from '../../../types/CompanyTypes';
import { Fetched, IDefaultProps } from '../../../types/Types';

interface IFinancialInfo {
    title: string | JSX.Element,
    values: {[y in number]: string | null},
    numberValues: {[y in number]: number | null},
    style?: string | null
}
type FinancialInfoRow = IFinancialInfo & {
    key: number | null
}
const FIRST_YEAR = new Date().getFullYear() - 30;

const REKSTRAR_KEYS = [110000, 120000, 910300, 130000, 910100, 135000, 910400, 140000, 910500, 150000, 151000, 152000, 160000, 910600, 170000, 180000, 910700];
const EFNAHAGS_KEYS = [200000, 210000, 220000, 300000, 400000, 410000, 420000, 920900];
const SJODSTREYMI_KEYS = [510000, 520000, 530000, 550000, 950700, 950900];
const KENNITOLUR_KEYS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]; // Made up keys for financial ratios.
const REKSTRAR_KEYS_FREE = [110000, 910700];
const EFNAHAGS_KEYS_FREE = [200000];
const SJODSTREYMI_KEYS_FREE = [950900];
const FREE_KEYS = [...REKSTRAR_KEYS_FREE, ...EFNAHAGS_KEYS_FREE, ...SJODSTREYMI_KEYS_FREE];

const getEmptyFinancialInfo = (title: string, style?: string) => ({
    title,
    style,
    values: {},
    numberValues: {}
})

const getEmptyFinancialRatioInfo = (mainTitle: string, description: string, style?: string) => ({
    title: <span className='ratios-title'>
        {mainTitle}
        <Tooltip
            text={<FontAwesomeIcon icon={faInfoCircle} />}
            tooltip={<>{description}</>}
            noUnderline
        />
    </span>,
    style,
    values: {},
    numberValues: {}
})

const REKSTRAR_BASE: {
    [T in number]: IFinancialInfo
} = {
    110000: getEmptyFinancialInfo('Rekstrartekjur'),
    120000: getEmptyFinancialInfo('Kostnaðarverð seldra vara'),
    910300: getEmptyFinancialInfo('Framlegð', 'bold'),
    130000: getEmptyFinancialInfo('Aðrar rekstrartekjur'),
    910100: getEmptyFinancialInfo('Heildartekjur', 'bold'),
    135000: getEmptyFinancialInfo('Rekstrarkostnaður'),
    910400: getEmptyFinancialInfo('EBITDA', 'bold'),
    140000: getEmptyFinancialInfo('Afskriftir og virðisrýrnun'),
    910500: getEmptyFinancialInfo('EBIT', 'bold'),
    150000: getEmptyFinancialInfo('Fjármagnsliðir'),
    151000: getEmptyFinancialInfo('Fjármunatekjur', 'italic'),
    152000: getEmptyFinancialInfo('Fjármagnsgjöld', 'italic'),
    160000: getEmptyFinancialInfo('Aðrir liðir'),
    910600: getEmptyFinancialInfo('EBT', 'bold'),
    170000: getEmptyFinancialInfo('Tekjuskattur'),
    180000: getEmptyFinancialInfo('Liðir eftir tekjuskatt'),
    910700: getEmptyFinancialInfo('Afkoma', 'bold')
}
const EFNAHAGS_BASE: {
    [T in number]: IFinancialInfo
} = {
    200000: getEmptyFinancialInfo('Eignir'),
    210000: getEmptyFinancialInfo('Fastafjármunir', 'italic'),
    220000: getEmptyFinancialInfo('Veltufjármunir', 'italic'),
    300000: getEmptyFinancialInfo('Eigið fé'),
    400000: getEmptyFinancialInfo('Skuldir'),
    410000: getEmptyFinancialInfo('Langtímaskuldir', 'italic'),
    420000: getEmptyFinancialInfo('Skammtímaskuldir', 'italic'),
    920900: getEmptyFinancialInfo('Eigið fé og skuldir', 'bold')
}
const SJODSTREYMI_BASE: {
    [T in number]: IFinancialInfo
} = {
    510000: getEmptyFinancialInfo('Handbært fé frá rekstri'),
    520000: getEmptyFinancialInfo('Fjárfestingahreyfingar'),
    530000: getEmptyFinancialInfo('Fjármögnunarhreyfingar'),
    550000: getEmptyFinancialInfo('Handbært fé í ársbyrjun'),
    950700: getEmptyFinancialInfo('Breyting á handbæru fé', 'bold'),
    950900: getEmptyFinancialInfo('Handbært fé í árslok')
}
const KENNITOLUR_BASE: {
    [T in number] : IFinancialInfo
} = {
    0: getEmptyFinancialInfo('Rekstur', 'group-head'),
    1: getEmptyFinancialRatioInfo(
        'Álagningarhlutfall',
        'Brúttó ágóði sem hlutfall af sölu'
    ),
    2: getEmptyFinancialRatioInfo(
        'Framlegðarhlutfall',
        'EBITDA sem hlutfall af rekstrartekjum'
    ),
    3: getEmptyFinancialRatioInfo(
        'Rekstrarhagnaðarhlutfall',
        'EBIT sem hlutfall af rekstrartekjum'
    ),
    4: getEmptyFinancialRatioInfo(
        'Vaxtaþekja',
        'EBIT sem hlutfall af fjármagnsgjöldum'
    ),
    5: getEmptyFinancialInfo('Efnahagur', 'group-head'),
    6: getEmptyFinancialRatioInfo(
        'Eiginfjárhlutfall',
        'Eigið fé sem hlutfall af heildareignum'
    ),
    7: getEmptyFinancialRatioInfo(
        'Veltufjárhlutfall',
        'Veltufjármunir sem hlutfall af skammtímaskuldum'
    ),
    8: getEmptyFinancialRatioInfo(
        'Lausafjárhlutfall',
        'Handbært fé sem hlutfall af skammtímaskuldum'
    ),
    9: getEmptyFinancialInfo('Arðsemi', 'group-head'),
    10: getEmptyFinancialRatioInfo(
        'Arðsemi eigin fjár',
        'Hagnaður ársins sem hlutfall af eigin fé síðasta árs'
    ),
    11: getEmptyFinancialRatioInfo(
        'Arðsemi eigna',
        'Hagnaður ársins sem hlutfall af eignum síðasta árs'
    )
}

type Props = ICompanyViewModel & IDefaultProps;
type CaretStatus = 'both' | 'left' | 'right' | 'none'

const CompanyFinancialInfo: React.FC<Props> = ({
    id,
    isSubscriber,
    accessToken
}) => {
    // We override the <Tabs> component with this local state.
    const [ currentTab, setCurrentTab ] = useState<'Rekstrarreikningur' | 'Efnahagsreikningur' | 'Sjodstreymi' | 'Kennitolur'>('Rekstrarreikningur');
    const [ caretStatus, setCaretStatus ] = useState<CaretStatus>('right');
    const [tableIndex, setTableIndex] = useState(0)
    const [columnCount, setColumnCount] = useState<number>(5)

    // Fetched Company Report data.
    const companyReportInfo = useFetchedApiLmdData<CompanyReportInfo[]>(
        (isSubscriber)
        ? `/v1/market_data/v1/company_reports/company_multiple_reports?company_id=${id}&year=${FIRST_YEAR}`
        : `/v1/market_data/v1/company_reports/company_multiple_reports_filtered?company_id=${id}&keys=[${FREE_KEYS.join(';')}]&year=${FIRST_YEAR}`,
        accessToken
    );
    const [companyFinancialRatios, setCompanyFinancialRatios] = useState<Fetched<ICompanyFinancialRatios[]>>([]);

    const fetchRatiosData = async () => {
        const url = `${GET_API_LMD_URL()}/v1/market_data/v1/company_reports/company_financial_ratios?company_id=${id}&year=${FIRST_YEAR}`;

        fetch(url, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        .then(res => {
            if (res.ok) return res.json()
            if (res.status === 404) throw new Error(ErrorMessages.NoDataFound);
            throw new Error(ErrorMessages.RequestFailed);
        })
        .then(resBody => {
            setCompanyFinancialRatios(resBody);
        })
        .catch(err => {
            if (err instanceof Error) setCompanyFinancialRatios(err);
            else setCompanyFinancialRatios(new Error(ErrorMessages.RequestFailed));
        });
    }

    useEffect(() => {
        if (isSubscriber && accessToken) fetchRatiosData();
    }, [isSubscriber, accessToken]);

    const [ yearToRateMap, setYearToRateMap ] = useState<{ [year in number]: number | null }>({});

    const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 767px)').matches);
    //changing number of columns when the screen changes
    useEffect(() => {
        if(isMobile) setColumnCount(2)
        else {
            setTableIndex(0);
            setColumnCount(5)
        }
    }, [isMobile])

    useEffect(() => {
        const checkMobile = () => setIsMobile(window.matchMedia('(max-width: 767px)').matches);
        window.addEventListener('resize', checkMobile);
        return () => {
        window.removeEventListener('resize', checkMobile);
        };
    }, []);
    // Calculated data from company report info.
    const {
        yearsAvailable,
        denominations,
        rekstrarData,
        efnahagsData,
        sjodstreymiData,
        yearToDenominationMap
    } = useMemo(() => {
        const yearsAvailable: number[] = [];
        let yearEnds: {[y in number]: string | null} = {};
        let denominations: {[y in number]: string | null} = {};
        let rekstrarData: {[i in number]: IFinancialInfo} = REKSTRAR_BASE;
        let efnahagsData: {[i in number]: IFinancialInfo} = EFNAHAGS_BASE;
        let sjodstreymiData: {[i in number]: IFinancialInfo} = SJODSTREYMI_BASE;
        const yearToDenominationMap: {
            [year in number]: {
                useNumbersIn: boolean,
                numbersIn: number | null,
                currency: string | null
            }
        } = {};

        if (companyReportInfo === null || companyReportInfo instanceof Error) return {
            yearsAvailable,
            denominations,
            rekstrarData,
            efnahagsData,
            sjodstreymiData,
            yearToDenominationMap
        };

        for (const { FiscalYear, PeriodEnd, UseNumbersIn, NumbersIn, Currency, KeyId, KeyValue } of companyReportInfo) {
            if (yearToDenominationMap[FiscalYear] === undefined) {
                yearToDenominationMap[FiscalYear] = {
                    useNumbersIn: UseNumbersIn,
                    currency: Currency,
                    numbersIn: NumbersIn
                }
            }
            if (!yearsAvailable.includes(FiscalYear)) {
                yearsAvailable.push(FiscalYear);
                yearEnds[FiscalYear] = getTableDate(PeriodEnd, 'DD/MM/YYYY', '.');
                denominations[FiscalYear] = `Í ${UseNumbersIn && NumbersIn && NumbersIn > 1 ? formatNumber(NumbersIn, null, 0, ' ') : ''}${Currency}`;
            }
            if (REKSTRAR_KEYS.includes(KeyId)) {
                rekstrarData[KeyId].values[FiscalYear] = formatNumber(KeyValue, '-', 0);
                rekstrarData[KeyId].numberValues[FiscalYear] = KeyValue;
            } else if (EFNAHAGS_KEYS.includes(KeyId)) {
                efnahagsData[KeyId].values[FiscalYear] = formatNumber(KeyValue, '-', 0);
                efnahagsData[KeyId].numberValues[FiscalYear] =  KeyValue;
            } else if (SJODSTREYMI_KEYS.includes(KeyId)) {
                sjodstreymiData[KeyId].values[FiscalYear] = formatNumber(KeyValue, '-', 0);
                sjodstreymiData[KeyId].numberValues[FiscalYear] = KeyValue;
            }
        }

        return {
            yearsAvailable,
            denominations,
            rekstrarData,
            efnahagsData,
            sjodstreymiData,
            yearToDenominationMap
        }
    }, [ companyReportInfo ]);

    const kennitolurData = useMemo(() => {
        const kennitolurData: {[i in number]: IFinancialInfo} = KENNITOLUR_BASE;
        if (companyFinancialRatios === null || companyFinancialRatios instanceof Error) return kennitolurData;

        for (const ratios of companyFinancialRatios) {
            const { FiscalYear, GrossMargin, EbitdaMargin, OperatingMargin, InterestCoverage, EquityRatio, CurrentRatio, QuickRatio, ReturnOnEquity, ReturnOnAssets } = ratios;
            kennitolurData[1].values[FiscalYear] = convertToPercentage(GrossMargin, true, 0);
            kennitolurData[1].numberValues[FiscalYear] = GrossMargin;
            kennitolurData[2].values[FiscalYear] = convertToPercentage(EbitdaMargin, true, 0);
            kennitolurData[2].numberValues[FiscalYear] = EbitdaMargin;
            kennitolurData[3].values[FiscalYear] = convertToPercentage(OperatingMargin, true, 0);
            kennitolurData[3].numberValues[FiscalYear] = OperatingMargin;
            kennitolurData[4].values[FiscalYear] = formatNumber(InterestCoverage, '-', 2);
            kennitolurData[4].numberValues[FiscalYear] = InterestCoverage;
            kennitolurData[6].values[FiscalYear] = convertToPercentage(EquityRatio, true, 0);
            kennitolurData[6].numberValues[FiscalYear] = EquityRatio;
            kennitolurData[7].values[FiscalYear] = formatNumber(CurrentRatio, '-', 2);
            kennitolurData[7].numberValues[FiscalYear] = CurrentRatio;
            kennitolurData[8].values[FiscalYear] = formatNumber(QuickRatio, '-', 2);
            kennitolurData[8].numberValues[FiscalYear] = QuickRatio;
            kennitolurData[10].values[FiscalYear] = convertToPercentage(ReturnOnEquity, true, 0);
            kennitolurData[10].numberValues[FiscalYear] = ReturnOnEquity;
            kennitolurData[11].values[FiscalYear] = convertToPercentage(ReturnOnAssets, true, 0);
            kennitolurData[11].numberValues[FiscalYear] = ReturnOnAssets;
        }

        return kennitolurData;
    }, [ companyFinancialRatios ]);

    useEffect(() => {
        const fetchCurrencyInfo = async (year: number, currency: string) => {
            const url = `${GET_API_LMD_URL()}/v1/market_data/v1/keldan/base/ISK/cross_currency/[${currency}]/sources/[CBI]/currency_timeseries?date_from=${year}-01-05&date_to=${year}-01-07`;
            fetch(url, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then(res => {
                if (res.ok) return res.json()
                else throw new Error(`${res.status}: ${res.statusText}`);
            })
            .then(resBody => {
                if (resBody && resBody.length > 0) {
                    setYearToRateMap((yearToRateMap) => ({
                        ...yearToRateMap,
                        [year]: resBody[0].Averagerate
                    }))
                }
            })
            .catch(err => {
                setYearToRateMap((yearToRateMap) => ({
                    ...yearToRateMap,
                    [year]: null
                }))
            })
        }
        for (const yearString in yearToDenominationMap) {
            const year = parseInt(yearString);
            const { currency } = yearToDenominationMap[year];
            if (currency !== null && currency !== 'ISK') {
                fetchCurrencyInfo(year, currency);
            }
        }
    }, [ yearToDenominationMap ]);

    const handleClick = (increment: boolean) => {
        let newTableIndex = increment ? tableIndex + 1 : tableIndex - 1;
        
        // Determine the caret status based on the new table index
        let newCaretStatus: CaretStatus;
    
        if (newTableIndex === 0 && yearsAvailable.length <= columnCount) {
            newCaretStatus = 'none';
        } else if (newTableIndex === 0) {
            newCaretStatus = 'right';
        } else if (newTableIndex + columnCount >= yearsAvailable.length) {
            newCaretStatus = 'left';
        } else if (yearsAvailable.length < columnCount) {
            newCaretStatus = 'both';
        } else {
            newCaretStatus = 'none';
        }
        setTableIndex(newTableIndex);
        setCaretStatus(newCaretStatus);
    };

    const getColumnTitle = (year: number, index: number) => {
        if (index === 0) {
            return <div className='header-container'>
                { caretStatus !== "left" && caretStatus !== "both" &&
                    <FontAwesomeIcon style={{marginRight: '10px'}} onClick={() => handleClick(true)} icon={faAngleLeft} className="year-slider-arrow icon-fa" />
                }
                {year.toString()}
            </div>
        }
        if (isMobile) {
            return <div className='header-container'>
                {year.toString()}
                {caretStatus !== "right" && caretStatus !== "both" &&
                    <FontAwesomeIcon style={{marginLeft: '10px'}} onClick={() => handleClick(false)} icon={faAngleRight} className="year-slider-arrow icon-fa" />
                }
            </div>
        }
        return year.toString();
    }

    const displayTable = () => {
        // Loading and error states.
        if (companyReportInfo === null) return <Loading />;
        if (companyReportInfo instanceof Error) return <ErrorAlert error={companyReportInfo} />;
        if (companyFinancialRatios === null) return <Loading />;
        if (companyFinancialRatios instanceof Error) return <ErrorAlert error={companyFinancialRatios} />;

        // First row is currency.
        const CURRENCY_ROW_TITLE = 'Gjaldmiðill';
        const rowsToDisplay: FinancialInfoRow[] = [{
            key: null,
            title: CURRENCY_ROW_TITLE,
            values: denominations,
            numberValues: {},
            style: null
        }];

        // Add different rows based on what the tab is.
        switch (currentTab) {
            case 'Rekstrarreikningur':
                rowsToDisplay.push(...REKSTRAR_KEYS.map(key => ({
                    key,
                    ...rekstrarData[key]
                })));
                break;
            case 'Efnahagsreikningur':
                rowsToDisplay.push(...EFNAHAGS_KEYS.map(key => ({
                    key,
                    ...efnahagsData[key]
                })));
                break;
            case 'Sjodstreymi':
                rowsToDisplay.push(...SJODSTREYMI_KEYS.map(key => ({
                    key,
                    ...sjodstreymiData[key]
                })));
                break;
            case 'Kennitolur':
                rowsToDisplay.push(...KENNITOLUR_KEYS.map(key => ({
                    key,
                    ...kennitolurData[key]
                })));
                break;
        }

        // Create dynamic columns based on years.
        const displayYearsAvailable = [...yearsAvailable].reverse().slice(tableIndex,tableIndex+columnCount).reverse();
        const yearColumns: IColumn<FinancialInfoRow>[] = displayYearsAvailable.map((year, index) => ({
            title: getColumnTitle(year, index),
            renderCell: ({ key, values, style }) => {
                if (isSubscriber || key == null || FREE_KEYS.includes(key)) {
                    return  style === 'group-head' ? null : (values[year] ?? '-')
                }
                return <Censored />
            }
        }));

        if (!isMobile && yearsAvailable.length >= 2) {
            let unadjustedData;
            switch (currentTab) {
                case 'Rekstrarreikningur':
                    unadjustedData = rekstrarData;
                    break;
                case 'Efnahagsreikningur':
                    unadjustedData = efnahagsData;
                    break;
                case 'Sjodstreymi':
                    unadjustedData = sjodstreymiData;
                    break;
                case 'Kennitolur':
                    unadjustedData = kennitolurData;
                    break;
            }

            const adjustedMap: {
                [key in number]?: number[]
            }= {};

            for (const key in unadjustedData) {
                const { numberValues } = unadjustedData[key];
                const adjustedNumberValues: number[] = [];
                
                let selectedItems: { [year: number]: {
                    useNumbersIn: boolean;
                    numbersIn: number | null;
                    currency: string | null;
                }} = Object.keys(yearToDenominationMap)
                    .filter((key: string) => displayYearsAvailable.includes(Number(key)))
                    .reduce((obj: { [year: number]: {
                        useNumbersIn: boolean;
                        numbersIn: number | null;
                        currency: string | null;
                    }}, key: string) => {
                        obj[Number(key)] = yearToDenominationMap[Number(key)];
                        return obj;
                    }, {});
                
                Object.entries(selectedItems).forEach(([yearString, { currency, numbersIn, useNumbersIn }], index) => {
                    const year = parseInt(yearString);
                    let value = numberValues[year] ?? 0;
                    // If number multiplier, apply it.
                    if (useNumbersIn && numbersIn !== null) {
                        value = value * numbersIn;
                    }
                    // if currency, multiply by rate
                    if (currency !== null && currency !== 'ISK') {
                        const rate = yearToRateMap[year];
                        if (rate !== null) {
                            value = value * rate;
                        }
                    }
                    adjustedNumberValues.push(value);
                });
                adjustedMap[key] = adjustedNumberValues;
            }

            yearColumns.push({
                title: <div className='header-container'>
                    {caretStatus !== "right" && caretStatus !== "both" && <FontAwesomeIcon onClick={() => handleClick(false)} icon={faAngleRight} className="year-slider-arrow icon-fa" />}
                    Þróun
                </div>,
                renderCell: ({key}) => {
                    if (key === null) return null;
                    if (isSubscriber || FREE_KEYS.includes(key)) {
                        return <SmallColumnChart
                            data={adjustedMap[key] ?? []}
                        />
                    }
                    return <Censored />
                },
                textAlign: 'right',
                overrideTh_class: (caretStatus !== "right" && caretStatus !== "both") ? 'column-with-arrow' : ''
            });
        }

        const columns: IColumn<FinancialInfoRow>[] = [{
            title: '',
            renderCell: ({title, key}) => (isSubscriber || key == null || FREE_KEYS.includes(key))
                ? title
                : <Censored />,
            textAlign: 'left',
        }, ...yearColumns];
        
        return (
            <Table
                tableSize='lg'
                data={rowsToDisplay}
                columns={columns}
                rowClassName={({ style }) => (style)
                    ? `text-${style}`
                    : ''
                }
                renderUnderRowComponent={({title}) => {
                    if (title === CURRENCY_ROW_TITLE) {
                        return (
                            <tr className='tab-switcher-row'>
                                <td colSpan={columns.length}>
                                    <div className='tabs-wrapper'>
                                        {/* Using "fake" tabs since they're inside the table. */}
                                        <Tabs
                                            onTabChange={(i) => {
                                                if (i === 0) setCurrentTab('Rekstrarreikningur');
                                                if (i === 1) setCurrentTab('Efnahagsreikningur');
                                                if (i === 2) setCurrentTab('Sjodstreymi');
                                                if (i === 3) setCurrentTab('Kennitolur');
                                            }}
                                            tabs={[{
                                                id: 'Rekstrarreikningur',
                                                label: 'Rekstrarreikningur',
                                                component: <></>
                                            }, {
                                                id: 'Efnahagsreikningur',
                                                label: 'Efnahagsreikningur',
                                                component: <></>
                                            }, {
                                                id: 'Sjodstreymi',
                                                label: 'Sjóðstreymi',
                                                component: <></>
                                            }, {
                                                id: 'Kennitolur',
                                                label: <>Kennitölur <Label labelType='new' text='Nýtt' size='sm' /></>,
                                                component: <></>
                                            }]}
                                        />
                                    </div>
                                </td>
                            </tr>
                        )
                    }
                    return null;
                }}
            />
        );
    }
    useEffect(() => {
        if (yearsAvailable.length <= columnCount) {
          setCaretStatus('both');
        } else if (tableIndex === 0) {
          setCaretStatus('right');
        }
      }, [yearsAvailable, columnCount, tableIndex]);
    return (
        <DisplayBox
            className='KCL_company-financial-info'
            titleComponent={
                <>
                    {!isSubscriber && <LockIcon />}
                    Fjárhagsupplýsingar
                </>
            }
            asideComponent={
                <Link
                    url={isSubscriber 
                        ? `/Excel/Fyrirtaeki-Fjarhagsupplysingar/${id}?year=${FIRST_YEAR}` 
                        : undefined
                    } 
                    linkSize='15'
                    icon={isSubscriber ? 'excel' : undefined}
                >
                    {!isSubscriber && <LockIcon />}
                    Gögn í Excel
                </Link>
            }
        >
            {displayTable()}
            {!isSubscriber && <SubscriptionBanner size='lg' raised={(currentTab === 'Sjodstreymi')} />}
        </DisplayBox>
    );
}

export default CompanyFinancialInfo;