// Package imports:
import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faTimes } from '@fortawesome/free-solid-svg-icons'
import { faAngleDown, faCircle, faCircleCheck, faCircleMinus, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { faCircle as emptyIcon } from '@fortawesome/pro-regular-svg-icons';
import AnimateHeight from 'react-animate-height';
// Component imports:
import FilterItem, { IStyleProps, IValueProps } from './FilterItem/FilterItem';
// Service imports:
import { isElementSubChild } from '../../services/utils';
// Type imports:
import { is_bus_categories } from '../../components/Frettir/News/News';

interface IProps {
    itemStyle: IStyleProps,
    itemValues: IValueProps[],
    columnStyle: 'grid' | 'flow' | 'categories',
    toggleAll?: ((category?: IValueProps[]) => void) | null,
    notAllSelectedBubble?: boolean,
    heading?: string,
    sortByCategory?: boolean
};

type SortedItems = {
    category: string | undefined;
    items: IValueProps[];
};
export const getNewsTypeCategories = (type:is_bus_categories ) => {
    switch(type) {
        case "press_release":
            return "Fréttatilkynningar";
        case "exchange_notice":
            return "Kauphallartilkynningar";
        case "news":
            return "Fréttir";
        case "article":
            return "Greinar";
        default:
            return "Annað";
    }
}
const getCategoryIcon = (items: IValueProps[]): IconDefinition => {
    if (items.every(item => item.selected)) return faCircleCheck;
    if (items.every(item => !item.selected)) return emptyIcon;
    return faCircleMinus; // no need to check some() - this is the only remaining case
}
const Filter: React.FC<IProps> = ({
    itemStyle,
    itemValues,
    columnStyle,
    toggleAll = null,
    notAllSelectedBubble,
    heading,
    sortByCategory
}) => {
    const filterRef = useRef<HTMLDivElement | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [activeCategory, setActiveCategory] = useState<string | undefined>(undefined);
    const toggleOpen = () => setIsOpen(!isOpen);

    useEffect(() => {
        const outOfFilterClickListener = (e: MouseEvent) => {
            const { target } = e;
            const filterElement = filterRef.current;
            if (target instanceof Element && filterElement) {
                // If user clicks on element, which is not in filter, then close filter.
                if (!isElementSubChild(target, filterElement)) {
                    setIsOpen(false)
                }
            } else {
                setIsOpen(false)
            }
        }
        document.addEventListener('click', outOfFilterClickListener);
        return () => document.removeEventListener('click', outOfFilterClickListener);
    })
    
    const allSelected = () => {
        return itemValues.every(obj => obj.hasOwnProperty('selected') && obj.selected === true);
    }

    const categorySort = (): SortedItems[] => {
        // Get unique categories
        const categories = Array.from(new Set(itemValues.map((item) => item.type)));
        
        // Define the order of categories
        // undefined for others
        const categoryOrder = [ "news", "article", "press_release", "exchange_notice", undefined];
        
        // Sort the categories based on the defined order
        categories.sort((a, b) => {
            return categoryOrder.indexOf(a) - categoryOrder.indexOf(b);
        });

        let sortedItems: SortedItems[] = [];
        for (let category of categories) {
            let categoryItems = itemValues.filter((item) => item.type === category);
            sortedItems.push({ category: getNewsTypeCategories(category as is_bus_categories), items: categoryItems });
        }
        return sortedItems;
    }


    const displayCategories = () => {
        const cat = categorySort();
            return cat.map((category) => {
                return (
                    <li 
                    style={{
                        cursor: category.category === activeCategory ? 'default' : 'pointer',
                        marginRight: '5px'
                    }} 
                    className={cx('category-wrapper', {'is-open': category.category === activeCategory})}
                    key={category.category}  
                    onClick={(e) => {
                            if(activeCategory === category.category) e.stopPropagation();
                            else setActiveCategory(category.category)
                        }}>
                        <div className="category-item">
                            <FontAwesomeIcon
                                className='fa-icon'
                                icon={getCategoryIcon(category.items)}
                                //if at least one selected -> deselect all, else select all
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleAll && toggleAll(category.items)}}
                                />
                            <div
                                className='category-header'
                                onClick={() => {
                                    if(activeCategory === category.category) setActiveCategory(undefined);
                                    }}>
                                    <div className='category-name-wrapper'>
                                        <span className='category-name'>{`${category.category}`}</span>
                                        <span className='category-length'>{`(${category.items.length})`}</span>
                                    </div>
                                    <FontAwesomeIcon 
                                        className={`fa-icon ${activeCategory === category.category ? 'rotate-open' : ''}`}
                                        icon={faAngleDown} 
                                    />
                            </div>
                        </div>
                        <AnimateHeight
                            className="category-item__content"
                            duration={300}
                            height={activeCategory === category.category ? 'auto' : 0}
                        > 
                            <div className="category-item__body">
                                {category.items.map((value) => (
                                    <FilterItem
                                        size={itemStyle.size}
                                        showCheck={itemStyle.showCheck}
                                        text={value.text}
                                        selected={value.selected}
                                        disabled={value.disabled}
                                        locked={value.locked}
                                        toggleSelected={value.toggleSelected}
                                    />
                                ))}
                            </div>
                        </AnimateHeight>
                    </li>
                )
            })
    }

    const displayItems = ( )=> {
        if(sortByCategory) {
           return displayCategories();
        }
        else return <>
          {toggleAll !== null
            ? <li>
                <FilterItem
                    size={itemStyle.size}
                    showCheck={itemStyle.showCheck}
                    text="Velja allt"
                    selected={itemValues.every((val) => val.selected)}
                    toggleSelected={toggleAll}
                />
            </li>
            : null}
            {itemValues.map((value) => (
                <li key={value.key ?? value.text}>
                    <FilterItem
                        size={itemStyle.size}
                        showCheck={itemStyle.showCheck}
                        text={value.text}
                        selected={value.selected}
                        disabled={value.disabled}
                        locked={value.locked}
                        toggleSelected={value.toggleSelected}
                    />
                </li>
            ))}
        </>
    }

    return (
        <div className="KCL_filter" ref={filterRef}>
            <button
                className="settingsButton"
                onClick={toggleOpen}
                title="Sía"
            >
                {notAllSelectedBubble && !allSelected() &&
                    <FontAwesomeIcon className='circle' icon={faCircle} />
                }
                <FontAwesomeIcon icon={faCog} />
            </button>
            <div className={cx('filter-list', {'is-open': isOpen})}>
                <button
                    className="closeButton"
                    onClick={toggleOpen}
                    title="Loka"
                >
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                <div className="list-wrapper">
                    {heading && <h3 style={{margin: 0}}>{heading}</h3>}
                    <ul className={`list-${columnStyle}`}>
                      {displayItems()}
                    </ul>
                </div>
            </div>
        </div>
    );
}


export default Filter;