// Package imports:
import React from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/pro-light-svg-icons'
import { /*faLock,*/ faCheckCircle } from '@fortawesome/pro-solid-svg-icons';

type ItemSize = 'sm' | 'lg';

export interface IStyleProps {
    size?: ItemSize,
    showCheck?: boolean
};

export interface IValueProps {
    text: string,
    key?: string,
    type?: string,
    selected?: boolean,
    disabled?: boolean,
    locked?: boolean,
    toggleSelected(): void
};

type Props = IStyleProps & IValueProps;

const FilterItem: React.FC<Props> = ({
    size,
    showCheck,
    text, 
    selected,
    disabled,
    locked,
    toggleSelected
}) => {
    return (
        <button
            className={cx('KCL_filter-item', size ?? 'lg', {'selected': selected, 'disabled': disabled})}
            onClick={() => (!disabled && toggleSelected())}
        >
            <span className="radio__icon">
                {showCheck && (
                    <FontAwesomeIcon icon={selected ? faCheckCircle : faCircle} />
                )}
            </span>
            {text}
            {/* {locked && (
                <span className='lock_icon'>
                    <FontAwesomeIcon icon={faLock} />
                </span>
            )} */}
        </button>
    );
}

export default FilterItem;