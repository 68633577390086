// Package imports:
import React, { createContext, useContext, useState } from "react";

interface HistoryRefreshContextType {
  refreshTrigger: number;
  triggerRefresh: () => void;
}

const HistoryRefreshContext = createContext<HistoryRefreshContextType>({
  refreshTrigger: 0,
  triggerRefresh: () => {},
});

export const HistoryRefreshProvider: React.FC<{ children: React.ReactNode }> =
  ({ children }) => {
    const [refreshTrigger, setRefreshTrigger] = useState<number>(0);

    const triggerRefresh = () => {
      setRefreshTrigger((prev) => prev + 1);
    };

    return (
      <HistoryRefreshContext.Provider
        value={{ refreshTrigger, triggerRefresh }}
      >
        {children}
      </HistoryRefreshContext.Provider>
    );
  };

export const useHistoryRefresh = () => useContext(HistoryRefreshContext);
