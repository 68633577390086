// Package imports:
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle } from '@fortawesome/pro-solid-svg-icons';
import Spinner from 'react-bootstrap/Spinner';
// Component imports:
import Table from '../../ui-elements/Table/Table';
import Button from '../../ui-elements/Button/Button';
import Link from '../../ui-elements/Link/Link';
import Alert from '../../ui-elements/Alert/Alert';
import Loading from '../../ui-elements/Loading/Loading';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
import HreyfingarModal from './HreyfingarModal';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
// Service imports:
import { formatNumber, getFullMonthAndYear, getTableDate } from '../../services/utils';
import { GET_KELDAN_API_URL } from '../../services/config';
import { ErrorMessages } from '../../services/errorMessages';
// Type imports:
import { IKeldanApiAllInvoicesModel, IInvoiceReference, IInvoiceUser, IInvoiceContainer, InvoiceModalInfo, IInvoice, InvoiceIdAndUserIdToIInvoiceUserDetailsMap, InvoiceIdToInvoiceCustomerResult, getUserDetailsMapId } from '../../types/MyKeldaTypes';
import { Fetched } from '../../types/Types';

type SubTableOptions = 'Notendur' | 'Tilvisun';
type InvoiceToSubTableMap = {
    [T in string]?: SubTableOptions | undefined
}

type InvoiceIdToIInvoiceUserMap = {
    [T in string]: IInvoiceUser[] | undefined | Error
}

type InvoiceIdToIInvoiceReferenceMap = {
    [T in string]: IInvoiceReference[] | undefined | Error
}

const Hreyfingar: React.FC= () => {
    // Local states:
    const [ invoiceModalInfo, setInvoiceModalInfo ] = useState<InvoiceModalInfo | null>(null);
    const [ invoiceToSubTableMap, setInvoiceToSubTableMap ] = useState<InvoiceToSubTableMap>({});
    // Main table data:
    const [ invoiceContainers, setInvoiceContainers ] = useState<Fetched<IInvoiceContainer[]>>(null);
    // Subtable data:
    const [ invoiceIdToInvoiceUserMap, setInvoiceIdToInvoiceUserMap ] = useState<InvoiceIdToIInvoiceUserMap>({});
    const [ invoiceIdToInvoiceReferenceMap, setInvoiceIdToInvoiceReferenceMap ] = useState<InvoiceIdToIInvoiceReferenceMap>({});
    // Modal data:
    const [ invoiceUserDetailsMap, setInvoiceUserDetailsMap ] = useState<InvoiceIdAndUserIdToIInvoiceUserDetailsMap>({});
    const [ invoiceCustomerResultMap, setInvoiceCustomerResultMap ] = useState<InvoiceIdToInvoiceCustomerResult>({});

    // Helper functions:
    const toggleSubTableForInvoiceId = (invoiceId: number, clickedButton: SubTableOptions) => {
        const currentSubTableForDatumId = invoiceToSubTableMap[invoiceId];
        if (clickedButton === currentSubTableForDatumId) {
            setInvoiceToSubTableMap({
                ...invoiceToSubTableMap,
                [invoiceId]: undefined
            })
        } else {
            setInvoiceToSubTableMap({
                ...invoiceToSubTableMap,
                [invoiceId]: clickedButton
            })
        }
    }

    // Get inital invoiceContainers.
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${GET_KELDAN_API_URL()}/MyKelda/ApiGetAllInvoices`);
                if (response.ok) {
                    const responseBody: IKeldanApiAllInvoicesModel = await response.json();
                    if (responseBody.invoiceContainers === null) {
                        setInvoiceContainers(new Error(responseBody.error ?? 'Error occured'));
                    } else {
                        setInvoiceContainers(responseBody.invoiceContainers);
                    }
                } else {
                    setInvoiceContainers(new Error(ErrorMessages.RequestFailed));
                }
            } catch (e) {
                setInvoiceContainers(new Error(ErrorMessages.NetworkError));
            }
        }
        fetchData();
    }, []);

    // Fetch InvoiceUser and InvoiceReference data
    const fetchUserData = async (newInvoiceIdToInvoiceUserMap: InvoiceIdToIInvoiceUserMap, invoiceId: string) => {
        try {
            const response = await fetch(`${GET_KELDAN_API_URL()}/MyKelda/InvoiceByUser?id=${invoiceId}`);
            if (!response.ok) {
                newInvoiceIdToInvoiceUserMap[invoiceId] = new Error(ErrorMessages.RequestFailed);
                return;
            }
            const responseBody = await response.json();
            newInvoiceIdToInvoiceUserMap[invoiceId] = responseBody;
        } catch (e) {
            newInvoiceIdToInvoiceUserMap[invoiceId] = new Error(ErrorMessages.NetworkError);
        }
    }
    const fetchReferenceData = async (newInvoiceIdToInvoiceReferenceMap: InvoiceIdToIInvoiceReferenceMap, invoiceId: string) => {
        try {
            const response = await fetch(`${GET_KELDAN_API_URL()}/MyKelda/InvoiceByReference?id=${invoiceId}`);
            if (!response.ok) {
                newInvoiceIdToInvoiceReferenceMap[invoiceId] = new Error(ErrorMessages.RequestFailed);
                return;
            }
            const responseBody = await response.json();
            newInvoiceIdToInvoiceReferenceMap[invoiceId] = responseBody;
        } catch (e) {
            newInvoiceIdToInvoiceReferenceMap[invoiceId] = new Error(ErrorMessages.NetworkError);
        }
    }
    const fetchUserOrReferenceData = async () => {
        let valuesChanged = false;
        const newInvoiceIdToInvoiceUserMap = {...invoiceIdToInvoiceUserMap};
        const newInvoiceIdToInvoiceReferenceMap = {...invoiceIdToInvoiceReferenceMap};
        const values = Object.entries(invoiceToSubTableMap);
        for (const [ invoiceId, subTableOption ] of values) {
            switch (subTableOption) {
                case 'Notendur':
                    if (invoiceIdToInvoiceUserMap[invoiceId] !== undefined) break;
                    valuesChanged = true;
                    await fetchUserData(newInvoiceIdToInvoiceUserMap, invoiceId);
                    break;
                case 'Tilvisun':
                    if (invoiceIdToInvoiceReferenceMap[invoiceId] !== undefined) break;
                    valuesChanged = true;
                    await fetchReferenceData(newInvoiceIdToInvoiceReferenceMap, invoiceId);
                    break;
            }
        }

        if (valuesChanged) {
            setInvoiceIdToInvoiceUserMap(newInvoiceIdToInvoiceUserMap);
            setInvoiceIdToInvoiceReferenceMap(newInvoiceIdToInvoiceReferenceMap);
        }
    }
    useEffect(() => {
        fetchUserOrReferenceData();
    }, [ invoiceToSubTableMap ]);

    // Fetch modal data:
    useEffect(() => {
        if (invoiceModalInfo === null) return;
        // Hreyfingar
        if (invoiceModalInfo.modalType === 'Hreyfingar') {
            const { invoice } = invoiceModalInfo;
            if (invoiceCustomerResultMap[invoice.id] === undefined) {
                fetchCustomerResult(invoice);
            }
        }
        // Notendur
        else if (invoiceModalInfo.modalType === 'Notendur') {
            const { invoiceUser } = invoiceModalInfo;
            if (invoiceUserDetailsMap[getUserDetailsMapId(invoiceUser)] === undefined) {
                fetchUserDetails(invoiceUser);
            }
        }
        // Tilvisun data is already fetched.
    }, [ invoiceModalInfo ]);

    const fetchUserDetails = async (invoiceUser: IInvoiceUser) => {
        try {
            const response = await fetch(`${GET_KELDAN_API_URL()}/MyKelda/InvoiceByUserDetails?id=${invoiceUser.id}&userId=${invoiceUser.userId}`);
            if (response.ok) {
                const responseBody = await response.json();
                setInvoiceUserDetailsMap({
                    ...invoiceUserDetailsMap,
                    [getUserDetailsMapId(invoiceUser)]: responseBody
                });
            } else {
                setInvoiceUserDetailsMap({
                    ...invoiceUserDetailsMap,
                    [getUserDetailsMapId(invoiceUser)]: new Error(ErrorMessages.RequestFailed)
                });
            }
        } catch (e) {
            setInvoiceUserDetailsMap({
                ...invoiceUserDetailsMap,
                [getUserDetailsMapId(invoiceUser)]: new Error(ErrorMessages.NetworkError)
            });
        }
    }

    const fetchCustomerResult = async (invoice: IInvoice) => {
        try {
            const response = await fetch(`${GET_KELDAN_API_URL()}/MyKelda/InvoiceByCustomer?id=${invoice.id}`);
            if (response.ok) {
                const responseBody = await response.json();
                // Sometimes response is ok and has body but is still error.
                if (responseBody?.result !== undefined) {
                    setInvoiceCustomerResultMap({
                        ...invoiceCustomerResultMap,
                        [invoice.id]: new Error(ErrorMessages.OtherShort)
                    });
                } else {
                    setInvoiceCustomerResultMap({
                        ...invoiceCustomerResultMap,
                        [invoice.id]: responseBody
                    });
                }
            } else {
                setInvoiceCustomerResultMap({
                    ...invoiceCustomerResultMap,
                    [invoice.id]: new Error(ErrorMessages.RequestFailed)
                });
            }
        } catch (e) {
            setInvoiceCustomerResultMap({
                ...invoiceCustomerResultMap,
                [invoice.id]: new Error(ErrorMessages.NetworkError)
            });
        }
    }

    const getSubTableIcon = (invoiceId: number, type: 'Notendur' | 'Tilvisun') => {
        // Check if closed:
        if (invoiceToSubTableMap[invoiceId] !== type) {
            return <FontAwesomeIcon className='fa-icon' icon={faPlusCircle} />
        }
        // Check if loading
        if ((type === 'Notendur' && invoiceIdToInvoiceUserMap[invoiceId] === undefined)
            || (type === 'Tilvisun' && invoiceIdToInvoiceReferenceMap[invoiceId] === undefined)
        ) {
            return <Spinner className='loading-spinner' animation="border" />
        }
        // Else open.
        return <FontAwesomeIcon className='fa-icon' icon={faMinusCircle} />
    }

    const displayData = () => {
        if (invoiceContainers === null) return <Loading />;
        if (invoiceContainers instanceof Error) return <Alert type='error' headText={invoiceContainers.message} />;
        return (
            <Table
                data={invoiceContainers}
                tableSize='lg'
                className='main-table'
                rowClassName={({invoice}) => (invoiceToSubTableMap[invoice.id] === undefined) ? undefined : 'table__opened-row'}
                columns={[{
                    title: 'Mánuður',
                    renderCell: ({invoice}) => getFullMonthAndYear(new Date(invoice.fromDate)),
                    textAlign: 'left',
                },{
                    title: 'Frá',
                    renderCell: ({invoice}) => getTableDate(invoice.fromDate.toString(), 'DD/MM/YYYY HH:MM', '.'),
                    textAlign: 'left',
                },{
                    title: 'Til',
                    renderCell: ({invoice}) => getTableDate(invoice.toDate.toString(), 'DD/MM/YYYY HH:MM', '.'),
                    textAlign: 'left',
                },{
                    title: 'Greitt',
                    renderCell: ({invoice}) => formatNumber(invoice.amountTotal, '-', 0, ' kr.'),
                    textAlign: 'left',
                },{
                    title: 'Sundurliðun',
                    textAlign: 'left',
                    renderCell: ({invoice}) => (
                        <div
                            className='table__openSubTable-button'
                            onClick={() => toggleSubTableForInvoiceId(invoice.id, 'Notendur')}
                        >
                            {getSubTableIcon(invoice.id, 'Notendur')}
                            <span className='table__sundurlidun-button-text'>
                                <Link>Notendur</Link>
                            </span>
                        </div>
                    ),
                },{
                    title: 'Sundurliðun',
                    textAlign: 'left',
                    renderCell: ({invoice}) => (
                        <div
                            className='table__openSubTable-button'
                            onClick={() => toggleSubTableForInvoiceId(invoice.id, 'Tilvisun')}
                        >
                            {getSubTableIcon(invoice.id, 'Tilvisun')}
                            <span className='table__sundurlidun-button-text'>
                                <Link>Tilvísun</Link>
                            </span>
                        </div>
                    ),
                },{
                    title: 'Hreyfingar',
                    textAlign: 'center',
                    renderCell: ({invoice}) => (
                        (new Date(invoice.toDate).getMonth() === new Date().getMonth()
                        && new Date(invoice.toDate).getFullYear() === new Date().getFullYear()) 
                        ? null
                        : <div>
                            <Button
                                buttonType='secondary'
                                size='sm'
                                showLoader={(
                                    invoiceModalInfo?.modalType === 'Hreyfingar'
                                    && invoiceModalInfo.invoice.id === invoice.id
                                    && invoiceCustomerResultMap[invoice.id] === undefined
                                )}
                                onClick={() => {
                                    setInvoiceModalInfo({
                                        modalType: 'Hreyfingar',
                                        invoice
                                    })
                                }}
                            >
                                Skoða
                            </Button>
                        </div>
                    ),
                },{
                    title: '',
                    renderCell: ({invoice}) => (
                    (new Date(invoice.toDate).getMonth() === new Date().getMonth()
                    && new Date(invoice.toDate).getFullYear() === new Date().getFullYear()) 
                    ? <div>
                        <Link
                            url='/Excel/Hreyfingar/-1'
                            targetBlank
                            icon='excel'
                        >
                            Gögn í Excel
                        </Link>
                    </div>
                    : <div>
                        <Link
                            url={`/Excel/Hreyfingar/${invoice.id}`}
                            targetBlank
                            icon='excel'
                        >
                            Gögn í Excel
                        </Link>
                    </div>
                    ),
                    textAlign: 'right',
                }]}
                renderUnderRowComponent={({invoice}) => {
                    const subTableOption = invoiceToSubTableMap[invoice.id];
                    if (subTableOption === 'Notendur') {
                        const invoiceUserData = invoiceIdToInvoiceUserMap[invoice.id];
                        if (invoiceUserData === undefined) {
                            return null
                        } else if (invoiceUserData instanceof Error) {
                            return (
                                <tr className='section__table__sub-table'>
                                    <td colSpan={8} className='full-width-td'>
                                        <div className='section__table__sub-table__inner'>
                                            <ErrorAlert error={invoiceUserData} />
                                        </div>
                                    </td>
                                </tr>
                            );
                        }

                        return (
                            <tr className='section__table__sub-table'>
                                <td colSpan={8} className='full-width-td'>
                                    <div className='section__table__sub-table__inner'>
                                        <Table
                                            data={invoiceUserData}
                                            tableSize='lg'
                                            columns={[{
                                                title: 'Notandi',
                                                renderCell: ({userName}) => userName,
                                                textAlign: 'left'
                                            }, {
                                                title: 'Dagsetning',
                                                renderCell: ({invoiceDate}) => getTableDate(invoiceDate.toString(), 'DD/MM/YYYY', '.')
                                            }, {
                                                title: 'Verð',
                                                renderCell: ({amountTotal}) => formatNumber(amountTotal, '-', 0, ' kr.')
                                            }, {
                                                title: 'Verð án vsk.',
                                                renderCell: ({amount}) => formatNumber(amount, '-', 0, ' kr.')
                                            }, {
                                                title: 'Vsk.',
                                                renderCell: ({amountVat}) => formatNumber(amountVat, '-', 0, ' kr.')
                                            }, {
                                                title: 'Skýrslur',
                                                renderCell: (invoiceUser) => (
                                                    <Button 
                                                        size='sm'
                                                        buttonType='secondary'
                                                        showLoader={(
                                                            invoiceModalInfo?.modalType === 'Notendur'
                                                            && invoiceUser === invoiceModalInfo.invoiceUser
                                                            && invoiceUserDetailsMap[getUserDetailsMapId(invoiceModalInfo.invoiceUser)] === undefined
                                                        )}
                                                        onClick={() => {
                                                            setInvoiceModalInfo({
                                                                modalType: 'Notendur',
                                                                invoiceUser
                                                            })
                                                        }}
                                                    >
                                                        Skoða
                                                    </Button>
                                                )
                                            }]}
                                        />
                                    </div>
                                </td>
                            </tr>
                        )
                    } else if (subTableOption === 'Tilvisun') {
                        const invoiceReferenceData = invoiceIdToInvoiceReferenceMap[invoice.id];
                        if (invoiceReferenceData === undefined) {
                            return null
                        } else if (invoiceReferenceData instanceof Error) {
                            return (
                                <tr className='section__table__sub-table'>
                                    <td colSpan={8} className='full-width-td'>
                                        <div className='section__table__sub-table__inner'>
                                            <ErrorAlert error={invoiceReferenceData} />
                                        </div>
                                    </td>
                                </tr>
                            )
                        }

                        return (
                            <tr className='section__table__sub-table'>
                                <td colSpan={8} className='full-width-td'>
                                    <div className='section__table__sub-table__inner'>
                                        <Table
                                            data={invoiceReferenceData}
                                            tableSize='lg'
                                            columns={[{
                                                title: 'Tilvísun',
                                                renderCell: ({ref}) => ref,
                                                textAlign: 'left'
                                            }, {
                                                title: 'Verð',
                                                renderCell: ({totalWithVat}) => formatNumber(totalWithVat, '-', 0, ' kr.')
                                            }, {
                                                title: 'Verð án vsk.',
                                                renderCell: ({total}) => formatNumber(total, '-', 0, ' kr.')
                                            }, {
                                                title: 'Vsk.',
                                                renderCell: ({vat}) => formatNumber(vat, '-', 0, ' kr.')
                                            }, {
                                                title: 'Skýrslur',
                                                renderCell: (invoiceReference) => (
                                                    <Button 
                                                        size='sm'
                                                        buttonType='secondary'
                                                        onClick={() => {
                                                            setInvoiceModalInfo({
                                                                modalType: 'Tilvisun',
                                                                invoiceReference
                                                            })
                                                        }}
                                                    >
                                                        Skoða
                                                    </Button>
                                                )
                                            }]}
                                        />
                                    </div>
                                </td>
                            </tr>
                        )
                    }
                    return null;
                }}
            />
        )
    }
    
    return (
        <div className='KCL_Hreyfingar'>
            <HreyfingarModal
                invoiceModalInfo={invoiceModalInfo}
                invoiceUserDetailsMap={invoiceUserDetailsMap}
                invoiceCustomerResultMap={invoiceCustomerResultMap}
                onHide={() => setInvoiceModalInfo(null)}
            />
            <div className='shell'>
                <HeadSection
                    title='Hreyfingar'
                    showRecentReports
                />
                <div className='section'>
                    <div className='section__table'>
                        {displayData()}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Hreyfingar;
