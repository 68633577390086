// Package imports:
import React, { useRef } from "react";
import Bugsnag from "@bugsnag/js";
import * as Yup from "yup";
import { Field, FieldProps, Form, Formik, useField } from "formik";
// Component imports:
import Button from "../../ui-elements/Button/Button";
import Input from "../../ui-elements/Input/Formik/Input";
import Tooltip from "../../ui-elements/Tooltip/Tooltip";
import DropSelectSearch from "../../ui-elements/DropSelect/DropSelectSearch/DropSelectSearch";
// Service imports:
import { useBuyingProcess } from "../../services/buyhook";
import { GET_KELDAN_API_URL } from "../../services/config";
import { ErrorMessages } from "../../services/errorMessages";
// Type imports:
import { IKeldanApiResponse } from "../../types/KeldanTypes";
//Constants imports:
import { CountryList } from "../../assets/staticData/CountryList";
import { ForeignCompanyPep } from "../../types/ForeignCompanyPepTypes";
import { useHistoryRefresh } from "../../contexts/HistoryRefreshContext";

interface IProps {
  isAuthenticated: boolean;
  changeSearchResults: (data: ForeignCompanyPep | null | undefined) => void;
  isDisabled: boolean;
}

interface IFormProps {
  values: ISubFormValues;
  type: "disabled" | "enabled";
}

interface ISubFormValues {
  country_code: string;
  name: string;
}

const ForeignPepSearchCompanyForm: React.FC<IProps> = ({ isDisabled, changeSearchResults }) => {
  const { triggerRefresh } = useHistoryRefresh();
  const { tryPurchaseItem, tryToBuyItem, resetState, ModalsAndToasts } = useBuyingProcess(
    async (values: ISubFormValues, reference: string) => {
      changeSearchResults(null);
      try {
        const requestQuery = new URLSearchParams({
          //required parameters
          country_code: values.country_code,
          name: values.name,
        });

        //optional parameters
        if (reference) {
          requestQuery.append("reference", reference);
        }

        const url = `${GET_KELDAN_API_URL()}/Valistar/Erlend-Skyrsla-Logadilar-Kaupa`;
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
          body: requestQuery,
        });
        if (!response.ok) {
          resetState({
            headText: ErrorMessages.RequestFailed,
            type: "alert",
          });
        }
        if (response.redirected) {
          window.location.href = response.url;
          return;
        }
        const responseBody: IKeldanApiResponse<ForeignCompanyPep> = await response.json();
        if (responseBody.success) {
          //update history button dropdown
          triggerRefresh();
          changeSearchResults(responseBody.result);
          searchResultsRef.current?.scrollIntoView();
          return;
        }
        const { message } = responseBody.result as { message: string };
        if (message === "User or customer not subscribed to PEP") {
          resetState({
            headText: ErrorMessages.NoAccess,
            type: "error",
          });
        } else {
          resetState({
            headText: message ?? ErrorMessages.Other,
            type: "error",
          });
        }
      } catch (e) {
        if (e instanceof Error) Bugsnag.notify(e);
        resetState({ headText: ErrorMessages.NetworkError, type: "error" });
      }
    },
    "alþjóðlegum válistum"
  );
  const searchResultsRef = useRef<HTMLDivElement>(null);

  const MyForm: React.FC<IFormProps> = ({ values, type }) => {
    return (
      <Form>
        <div className="form__body" style={{ marginBottom: "60px" }}>
          <div className="form__section">
            <div className="form__row">
              <div className="form__col" style={{ maxWidth: "175px" }}>
                <Field
                  name="country_code"
                  id="country_code"
                  as={FormikDropSelectSearch}
                  disabled={type === "disabled"}
                  size="lg"
                  label="Landakóði"
                  options={CountryList}
                  defaultValue={values.country_code}
                />
              </div>
              <div className="form__col">
                <Input
                  disabled={type === "disabled"}
                  label="Nafn fyrirtækis"
                  name="name"
                  id="name"
                  value={values.name}
                />
              </div>
              <div style={{ flexGrow: 0 }} className="form__col form__actions">
                <Button
                  type="submit"
                  buttonType="buy"
                  showLoader={tryToBuyItem !== null}
                  disabled={type === "disabled"}
                  size="lg"
                >
                  Kaupa
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    );
  };

  const displayForm = (type: "disabled" | "enabled") => (
    <Formik
      initialValues={{
        country_code: "",
        name: "",
      }}
      validationSchema={Yup.object({
        country_code: Yup.string().required("Vinsamlegast veldu landakóða"),
        name: Yup.string().required("Vinsamlegast sláðu inn nafn"),
      })}
      onSubmit={(values) => {
        tryPurchaseItem(values, "confirm-modal-with-reference");
      }}
      component={(props: { values: ISubFormValues }) => <MyForm values={props.values} type={type} />}
    />
  );

  return (
    <>
      {!isDisabled ? (
        displayForm("enabled")
      ) : (
        <Tooltip
          tooltip={<span>Eingöngu fyrir tilkynningarskylda aðila í áskrift.</span>}
          text={displayForm("disabled")}
        />
      )}
      <ModalsAndToasts />
    </>
  );
};

export default ForeignPepSearchCompanyForm;
interface FormikDropSelectSearchProps extends FieldProps {
  label?: string;
  options: { value: string; label: string }[];
  disabled?: boolean;
  size?: "sm" | "md" | "lg";
  defaultValue?: string;
  name: string;
}
const FormikDropSelectSearch: React.FC<FormikDropSelectSearchProps> = ({
  label,
  options,
  disabled,
  defaultValue,
  size,
  name,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;

  return (
    <>
      <DropSelectSearch
        error={meta.touched && meta.error ? meta.error : undefined}
        {...field}
        {...props}
        disabled={disabled}
        size="lg"
        label="Landakóði"
        options={CountryList}
        defaultValue={defaultValue}
        onChange={(value) => setValue(value)}
      />
    </>
  );
};
