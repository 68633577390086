// Package imports:
import React from "react";
import * as Yup from 'yup';
import { Form, Formik } from "formik";
import Bugsnag from "@bugsnag/js";
// Component imports:
import Input from '../../../ui-elements/Input/Formik/Input';
import Button from '../../../ui-elements/Button/Button';
import Alert from "../../../ui-elements/Alert/Alert";
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { ErrorMessages } from "../../../services/errorMessages";
// Type imports:
import { MyCardViewModel } from "../../../types/MyKeldaTypes";

interface IFormProps {
    values: IFormValues
}
interface IFormValues {
    cardNumber: string,
    expiration: string,
    cvc: string,
}
interface IProps {
    setCreditCardInfo(name: MyCardViewModel): void,
    setContactResponse: (a: JSX.Element) => void,
    isAdmin: boolean
}

const GreidsluupplysingarCardForm: React.FC<IProps> = ({
    setCreditCardInfo,
    setContactResponse,
    isAdmin
}) => {

    const handleSubmit = async (values: IFormValues) => {
        try {
            setContactResponse(<></>);
            const requestQuery = new URLSearchParams({
                cardNumber: values.cardNumber,
                expiration: values.expiration,
                cvc: values.cvc
            });
            const url = `${GET_KELDAN_API_URL()}/MyKelda/CreditCard`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: requestQuery
            });
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                } else {
                    const responseBody: MyCardViewModel = await response.json();

                    if (responseBody.errors && responseBody.errors.length > 0) {
                        setContactResponse(<Alert type="error" headText={responseBody.errors.join('\n')} />);
                    }
                    else {
                        setContactResponse(<Alert type="success" headText='Kreditkorta upplýsingar vistaðar' />);
                        setCreditCardInfo(responseBody)
                    }
                }
            } else {
                setContactResponse(<Alert type="error" headText={ErrorMessages.RequestFailed} />);
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            setContactResponse(<Alert type="error" headText={ErrorMessages.NetworkError} />);
        }
    }

    const form: React.FC<IFormProps> = ({
        values
    }) => <Form>
        <div className="form__body">
            <div className="form__row main-form-row">
                <div className="form__col cardnumber-column">
                    <Input
                        label="Kortanúmer"
                        name="cardNumber"
                        id="cardNumber"
                        value={values.cardNumber}
                        disabled={!isAdmin}
                    />
                </div>
                <div className="form__col">
                    <div className="form__row form__row-mobile">
                        <div className="form__col">
                            <Input
                                label="MM &nbsp;/&nbsp; YY"
                                name="expiration"
                                id="expiration"
                                value={values.expiration}
                                disabled={!isAdmin}
                            />
                        </div>
                        <div className="form__col cvc-column">
                            <Input
                                label="CVC"
                                name="cvc"
                                id="cvc"
                                value={values.cvc}
                                disabled={!isAdmin}
                            />
                            <div className="form__icon" style={{marginLeft: '10px'}}>
                                <object data="https://cdn.livemarketdata.com/KeldanImages/cvc.svg">CVC</object>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="form__actions text__center">
            <Button
                type='submit'
                buttonType="primary"
                size="lg"
                disabled={!isAdmin}
            >
                Vista
            </Button>
        </div>
    </Form>
    return (
        <div className="KCL_Greidsluupplysingar-form modal-form">
            <Formik
                initialValues={{
                    cardNumber: '',
                    expiration: '',
                    cvc: '',
                }}
                validationSchema={Yup.object({
                    cardNumber: Yup.string()
                        .required('Kortanúmer verður að fylgja'),
                    expiration:  Yup.string()
                        .required('Gildistími verður að fylgja')
                        .matches(/\d{2}\/\d{2}/, 'Gildistími verður að vera á forminu MM/YY'),
                    cvc: Yup.string()
                        .required('CVC verður að fylgja')
                        .matches(/(\d{3})/, 'CVC verður að vera 3 tölustafir'),
                })}
                onSubmit={handleSubmit}
                component={form}
            />
        </div>
    )
}

export default GreidsluupplysingarCardForm;