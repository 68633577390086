// Package imports:
import React, { useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { faAngleDown, faCircleCheck, faCircleMinus } from '@fortawesome/pro-solid-svg-icons';
import AnimateHeight from 'react-animate-height';
// Component imports:
import SmallSearch from '../../SmallSearch/SmallSearch';
import FrettavaktItem from '../FrettavaktItem/FrettavaktItem';
import Loading from '../../../ui-elements/Loading/Loading';
import ErrorAlert from '../../ErrorAlert/ErrorAlert';
import Button from '../../../ui-elements/Button/Button';
import FilterItem, { IValueProps } from '../../../ui-elements/Filter/FilterItem/FilterItem';
import Filter, { getNewsTypeCategories } from '../../../ui-elements/Filter/Filter';
import AdRotator from '../../Ad/AdRotator';
// Service imports:
import { sortIcelandic, updateDoubleMapValue } from '../../../services/utils';
import { GET_NEWS_LMD_URL } from '../../../services/config';
import { getSourceListFromSourceString, getSourceStringFromSourceList } from '../../../services/newsUtils';
import { useStateRef } from '../../../services/hooks';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { Fetched, IDefaultProps } from '../../../types/Types';
import { INewsFeedCategoryDetails, INewsFeedSourceFilter, INewsLmdNewsItem, INewsLmdResponse, SearchInputToSourceFilterToResultsDoubleMap, SearchResultsInfo, SourceFilterToResultsMap, NewsItemFeedIdMapToBoolean, INewsFeedSourcesResponse } from '../../../types/NewsTypes';
import { is_bus_categories } from '../../Frettir/News/News';

/**
 * Here is what these words mean:
 * - incomplete: There are less items being displayed than should be. Must fetch more.
 * - complete: There are enough items being displayed, but more can be loaded if the user wants.
 * - fully loaded: We are displaying literally all news for the given search query.
 * - no data: It is still loading or there is an error. No data is present.
 */
type DataStatus = 'incomplete' | 'complete' | 'fully loaded' | 'no data' ;

interface IDataMemo {
    dataToDisplay: INewsLmdNewsItem[] | null,
    dataStatus: DataStatus
};

const ITEMS_TO_DISPLAY_PER_FETCH = 30;
const INTIAL_REFRESH_RATE_NEWS_ITEMS_TO_FETCH = 50;
const NEWS_ITEMS_FETCH_ON_INDEX_CHANGE = 100;

const MINIMUM_LETTER_SEARCH = 3;

interface IOwnProps {
    // setHasNew(hasNewNewsItems: boolean): void,
    newsFeedCategoryDetails: INewsFeedCategoryDetails
}

type Props = IDefaultProps & IOwnProps;

const FrettavaktContainer: React.FC<Props> = ({
    // setHasNew,
    newsFeedCategoryDetails,
    refreshRateMs
}) => {
    // State variables:
    // News items visible to the user.
    const [ defaultNewsItems, setDefaultNewsItems ] = useState<INewsLmdNewsItem[] | null>(null);
    // How many news items should be displayed. newsItemsData will fetch more than the user wants to use the effciency of the request.
    const [ itemsPerPage, setItemsPerPage ] = useState(ITEMS_TO_DISPLAY_PER_FETCH);
    // Any error if it would occur
    const [newsError, setNewsError] = useState<Error | null>(null);
    // Sources used to filter by.
    const [sourceFilters, setSourceFilters] = useState<Fetched<INewsFeedSourceFilter[]>>(null);
    // Search word
    const [searchInput, setSearchInput] = useState('');
    // Search word results map
    const [searchResultsDoubleMap, setSearchResultsDoubleMap] = useState<SearchInputToSourceFilterToResultsDoubleMap>({});
    // While loading new data, show the last displayable data. Makes searching more UX friendly
    const [lastDisplayableData, setLastDisplayableData] = useState<INewsLmdNewsItem[] | null>(null);
    
    const [activeCategory, setActiveCategory] = useState<string | undefined>(undefined);
    // Memo variables:
    const usingSourceFilter = useMemo(() => {
        if (sourceFilters === null || sourceFilters instanceof Error) return 'all';
        if (sourceFilters.every(sourceFilter => !sourceFilter.isOn)) return 'none';
        if (sourceFilters.every(sourceFilter => sourceFilter.isOn)) return 'all';
        return 'some';
    }, [ sourceFilters ]);

    const usingSearchWord = useMemo(() => {
        return (searchInput.length >= MINIMUM_LETTER_SEARCH);
    }, [ searchInput ]);
    
    const usingDefaultNews = useMemo(() => {
        return (!usingSearchWord && usingSourceFilter === 'all');
    }, [ usingSearchWord, usingSourceFilter ]);

    const { dataToDisplay, dataStatus } = useMemo(() => {
        const noDataState: IDataMemo = {
            dataToDisplay: [],
            dataStatus: 'no data'
        }
        if (usingSourceFilter === 'none') return noDataState;

        if (usingDefaultNews) {
            if (defaultNewsItems === null) return noDataState;
            const dataSlice = defaultNewsItems.slice(0, itemsPerPage);
            const dataMemo: IDataMemo = {
                dataToDisplay: dataSlice,
                dataStatus: (dataSlice.length < itemsPerPage) ? 'incomplete' : 'complete'
            }
            return dataMemo;
        }

        const searchWord = (usingSearchWord)
            ? searchInput
            : '';
        const sourceFilterString = (usingSourceFilter === 'some')
            ? getSourceStringFromSourceList(sourceFilters, 'on')
            : '';

        if (sourceFilterString === null) return noDataState;
        const searchResults = searchResultsDoubleMap?.[searchWord]?.[sourceFilterString];

        if (searchResults === undefined || searchResults.results instanceof Error) return noDataState;

        const dataSlice = searchResults.results.slice(0, itemsPerPage);
        let dataStatus: DataStatus;
        if (dataSlice.length === searchResults.lastResultsLength || dataSlice.length === searchResults.totalCount) {
            dataStatus = 'fully loaded';
        } else if (dataSlice.length === itemsPerPage) {
            dataStatus = 'complete';
        } else {
            dataStatus = 'incomplete';
        }

        const dataMemo: IDataMemo = {
            dataToDisplay: dataSlice,
            dataStatus
        };
        return dataMemo;
    }, [ sourceFilters, searchInput, searchResultsDoubleMap, defaultNewsItems, itemsPerPage, usingSearchWord, usingSourceFilter ]);

    const localStorageKey = useMemo(() => {
        return `KELDAN_NEWS_SETTINGS_${newsFeedCategoryDetails.category}_OFF`;
    }, [newsFeedCategoryDetails]);

    // Maps to track if news are new or read.
    const [ newsItemFeedIdToIsSeenMap, setNewsItemFeedIdToIsSeenMap ] = useState<NewsItemFeedIdMapToBoolean>({});
    const [ newsItemFeedIdToIsNewMap, setNewsItemFeedIdToIsNewMap ] = useState<NewsItemFeedIdMapToBoolean>({});
    const [ newsItemFeedIdToIsHighlightedMap, setNewsItemFeedIdToIsHighlightedMap ] = useState<NewsItemFeedIdMapToBoolean>({});

    // Interval functions can't read state correctly. Needs ref's instead.
    const defaultNewsItemsRef = useStateRef(defaultNewsItems)
    const newsItemFeedIdToIsNewMapRef = useStateRef(newsItemFeedIdToIsNewMap);
    const newsItemFeedIdToIsHighlightedMapRef = useStateRef(newsItemFeedIdToIsHighlightedMap);
    const usingDefaultNewsRef = useStateRef(usingDefaultNews);
    const usingSearchWordRef = useStateRef(usingSearchWord);
    const searchResultsDoubleMapRef = useStateRef(searchResultsDoubleMap);
    const sourceFiltersRef = useStateRef(sourceFilters);

    // Ref for timeout id.
    const timeoutFunctionIdRef = useRef<number | null>(null);

    // HELPER FUNCTIONS:
    const combineNewAndOldNews = (newNews: INewsLmdNewsItem[], oldNews: INewsLmdNewsItem[]) => {
        const oldNewsIdMap = oldNews.reduce((obj: NewsItemFeedIdMapToBoolean, newsItem) => {
            const { id } = newsItem;
            if (!id) return obj;
            return {
                ...obj,
                [id]: true
            };
        }, {});
        const pureNewNews = newNews.filter(newsItem => (newsItem.id !== null && !oldNewsIdMap[newsItem.id]));

        // Append new news items onto visible news.
        const joinedListOfOldAndNewNews = newNews.concat(oldNews);
        // There could be some overlap between the end of visiblenews and the start of newnews items.
        // So we remove duplicates.
        const joinedNewsWithoutDuplicates = [];
        // Map newsitem.id to boolean. Faster timecomplexity than "visibleNewsWithoutDuplicates.includes()";
        const hasNewsItemBeenAdded: {
            [feedId in string]?: boolean
        } = {};

        for (const newsItem of joinedListOfOldAndNewNews) {
            if (newsItem.id === null) continue;
            if (hasNewsItemBeenAdded[newsItem.id]) continue;
            hasNewsItemBeenAdded[newsItem.id] = true;
            joinedNewsWithoutDuplicates.push(newsItem);
        }

        return {
            combinedNews: joinedNewsWithoutDuplicates,
            pureNewNews
        };
    }

    const newsItemOnOpen = (newsItem: INewsLmdNewsItem) => {
        const { id } = newsItem;
        if (id === null) return;
        // Set to "not new" if new
        setNewsItemFeedIdToIsNewMap({
            ...newsItemFeedIdToIsNewMap,
            [id]: false
        })
        setNewsItemFeedIdToIsHighlightedMap({
            ...newsItemFeedIdToIsHighlightedMap,
            [id]: false
        })
        // Set to seen if not seen
        setNewsItemFeedIdToIsSeenMap({
            ...newsItemFeedIdToIsSeenMap,
            [id]: true
        })
    }

    // Fetch helper function
    const fetchNews = (
        numberOfNewsItemsToFetch: number,
        onSuccess: (newsItems: INewsLmdNewsItem[], totalCount: number) => void,
        onFail: (err: Error) => void,
        startPublishDate?: number,
        searchQuery?: string,
        sourceFilterString?: string
    ) => {
        // Fetch items
        let baseUrl = GET_NEWS_LMD_URL();
        const urlSearchParams = new URLSearchParams({
            start: '0',
            limit: numberOfNewsItemsToFetch.toString(),
        });

        if(searchQuery)urlSearchParams.set('sortByField', 'score');
        // Search query has special URL ending.
        if (searchQuery || sourceFilterString) {
            baseUrl += '/search/query';
            if (searchQuery) {
                urlSearchParams.set('f', 'title;body');
                urlSearchParams.set('q', searchQuery?.toLowerCase() ?? '*');
                if (sourceFilterString) {
                    urlSearchParams.set('filter', `feedMeta.feedSymbol=${sourceFilterString}`);
                } else {
                    urlSearchParams.set('filter', `topics=${newsFeedCategoryDetails.category}`);
                }
            } else if (sourceFilterString) {
                urlSearchParams.set('f', 'feedMeta.feedSymbol');
                urlSearchParams.set('q', `(${sourceFilterString.replaceAll(';', ' ')})`);
            }
        }
        // If not search query, get the default url
        else {
            baseUrl += '/search/keldan';
            urlSearchParams.set('category', newsFeedCategoryDetails.category);
            if (startPublishDate) {
                urlSearchParams.set('startPublishDate', startPublishDate.toString());
            }
        }
        
        const url = `${baseUrl}?${urlSearchParams.toString()}`;

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            if (res.ok) return res.json();
            else throw new Error(ErrorMessages.RequestFailed);
        })
        .then((resBody: INewsLmdResponse) => {
            if (resBody === null || resBody.feedItems === null) throw new Error('Server did not return any news.')
            onSuccess(resBody.feedItems, resBody.totalCount);
        })
        .catch(err => {
            if (err instanceof Error) {
                onFail(err);
            } else {
                onFail(new Error(ErrorMessages.NetworkError));
            }
        });
    }

    // Update lastDisplayableData to last viable data.
    useEffect(() => { if (dataToDisplay !== null) setLastDisplayableData(dataToDisplay) }, [dataToDisplay]);

    // Reset items per page on search input change
    useEffect(() => {
        setItemsPerPage(ITEMS_TO_DISPLAY_PER_FETCH);
    }, [ searchInput, sourceFilters ]);

    // Sources fetch.
    useEffect(() => {
        const sourcesFetch = async () => {
            try {
                // Fetch items
                const url = `${GET_NEWS_LMD_URL()}/api/feeds?topics=${newsFeedCategoryDetails.category}`;

                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if (response.ok) {
                    const body: INewsFeedSourcesResponse = await response.json();
                    // Filter body by category.
                    const sourcesForCategory = body.feeds;
                    // Sort by name
                    sourcesForCategory.sort((source1, source2) => sortIcelandic(source1.feed.feedDisplayName, source2.feed.feedDisplayName))
                    // Add boolean value to the filter.
                    const sourceFiltersForCategory: INewsFeedSourceFilter[] = sourcesForCategory.map(newsFeedSource => ({
                        newsFeedSource,
                        isOn: true
                    }));
                    // Set default settings from local storage.
                    try {
                        const dontUseSettingsString = localStorage.getItem(localStorageKey);
                        if (dontUseSettingsString === null) {
                            setSourceFilters(sourceFiltersForCategory);
                            return;
                        }
                        const dontUseTitles = dontUseSettingsString.split(';');
                        const sourceFiltersForCategoryWithSavedSettings: INewsFeedSourceFilter[] = sourceFiltersForCategory.map(({ newsFeedSource }) => ({
                            newsFeedSource,
                            isOn: !dontUseTitles.includes(newsFeedSource.feed.feedSymbol)
                        }));
                        setSourceFilters(sourceFiltersForCategoryWithSavedSettings);
                        return;
                    } catch (e) {
                        // Localstorage error
                        setSourceFilters(sourceFiltersForCategory);
                    }
                } else {
                    setSourceFilters(new Error(ErrorMessages.RequestFailed));
                }
            } catch (e) {
                if (e instanceof Error) {
                    setSourceFilters(e);
                } else {
                    setSourceFilters(new Error(ErrorMessages.NetworkError));
                }
            }
        }
        sourcesFetch();
    }, []);

    // Initial data fetch:
    useEffect(() => {
        fetchNews(
            NEWS_ITEMS_FETCH_ON_INDEX_CHANGE,
            (newsItems) => {
                setDefaultNewsItems(newsItems);
            },
            (err) => {
                setNewsError(err);
            }
        )
    }, []);

    // DefaultNewsItems: "Load more news" based fetch
    useEffect(() => {
        // Can only load more if defaultnewsitems has been fetched, we are not searching, and there isn't enough news yet fetched.
        if (defaultNewsItems !== null && usingDefaultNews && dataStatus === 'incomplete') {
            const lastVisibleNewsitem = defaultNewsItems.slice(-1).pop();
            if (!lastVisibleNewsitem?.publish_timestamp) return;
            fetchNews(
                NEWS_ITEMS_FETCH_ON_INDEX_CHANGE,
                (olderNews) => {
                    const { combinedNews } = combineNewAndOldNews(defaultNewsItems, olderNews);
                    setDefaultNewsItems(combinedNews);
                },
                (err) => {
                    setNewsError(err);
                },
                lastVisibleNewsitem?.publish_timestamp
            );
        }
    }, [ dataStatus, usingDefaultNews, defaultNewsItems ]);

    // Refresh rate based fetch
    const newNewsRefreshFunction = () => {
        const defaultNewsItems = defaultNewsItemsRef.current;
        const newsItemFeedIdToIsNewMap = newsItemFeedIdToIsNewMapRef.current;
        const newsItemFeedIdToIsHighlightedMap = newsItemFeedIdToIsHighlightedMapRef.current;
        const usingDefaultNews = usingDefaultNewsRef.current;
        const usingSearchWord = usingSearchWordRef.current;
        const searchResultsDoubleMap = searchResultsDoubleMapRef.current;
        const sourceFilters = sourceFiltersRef.current;

        const combineOldAndNewNews = (newNewsItems: INewsLmdNewsItem[], oldNews: INewsLmdNewsItem[], sources: string[] | null) => {
            const newNewsItemsForSources = (sources === null)
                ? newNewsItems
                : newNewsItems.filter(newsItem => sources.includes(newsItem.source ?? ''));

            const firstOldNews = oldNews[0];
            let indexWhereOldNewsBegins = newNewsItemsForSources.findIndex(newsItem => (
                (
                    newsItem.id !== null
                    && newsItem.id === firstOldNews.id
                )
                || (
                    newsItem.publish_timestamp !== null
                    && firstOldNews.publish_timestamp !== null
                    && newsItem.publish_timestamp < firstOldNews.publish_timestamp
                )
            ));

            const addedNews = indexWhereOldNewsBegins !== -1 ? newNewsItemsForSources.slice(0, indexWhereOldNewsBegins) : [];
            const combinedNews = [...addedNews, ...oldNews];
            return {
                combinedNews,
                addedNews
            };
        }

        // Always update default news and non-searchword,yes-sourcefilter news.
        if (defaultNewsItems !== null) {
            const recursionStoppingNewsItem = (defaultNewsItems[0] ?? null) as INewsLmdNewsItem | null;
            if (recursionStoppingNewsItem === null || recursionStoppingNewsItem.id === null) return;
            const fetchNewNews = (numberToFetch: number, recursionTime = 0) => {
                fetchNews(
                    numberToFetch,
                    (newNewsItems) => {
                        // Check if it has retreived all new news.
                        // Do this by finding index of the first old news item. (old as in already fetched).
                        let indexWhereOldNewsBegins = newNewsItems.findIndex(newsItem => (
                            (
                                newsItem.id !== null
                                && newsItem.id === recursionStoppingNewsItem.id
                            )
                            || (
                                newsItem.publish_timestamp !== null
                                && recursionStoppingNewsItem.publish_timestamp !== null
                                && newsItem.publish_timestamp < recursionStoppingNewsItem.publish_timestamp
                            )
                        ));
                        // If not try again with higher limit.
                        if (indexWhereOldNewsBegins === -1) {
                            // RECURSION ALERT:
                            // If for some reason something goes wrong, this will break us out of recursion after 3 loops:
                            if (recursionTime < 3) fetchNewNews(numberToFetch*2, recursionTime+1);
                            return;
                        }

                        let newsThatGetNotification: INewsLmdNewsItem[] = [];

                        // Handle default news:
                        const { combinedNews, addedNews } = combineOldAndNewNews(newNewsItems, defaultNewsItems, null);
                        
                        // If usin
                        if (usingDefaultNews) newsThatGetNotification = addedNews;
                        setDefaultNewsItems(combinedNews);

                        // Handle each source filter case:
                        const sourceFilterToResultsMap = searchResultsDoubleMap?.[''];
                        const newSourceFilterToResultsMap: SourceFilterToResultsMap = { ...sourceFilterToResultsMap };
                        const currentSourceFilterString = getSourceStringFromSourceList(sourceFilters, 'on');
                        if (sourceFilterToResultsMap !== undefined) {
                            for (const sourceFilterString in sourceFilterToResultsMap) {
                                const searchResultInfo = sourceFilterToResultsMap?.[sourceFilterString];
                                if (searchResultInfo === undefined) continue;
                                const { results, totalCount } = searchResultInfo;
                                if (results instanceof Error) continue;
                                const { combinedNews, addedNews } = combineOldAndNewNews(newNewsItems, results, getSourceListFromSourceString(sourceFilterString));
                                
                                if (!usingSearchWord && currentSourceFilterString === sourceFilterString) {
                                    newsThatGetNotification = addedNews;
                                }
                                newSourceFilterToResultsMap[sourceFilterString] = {
                                    results: combinedNews,
                                    totalCount: totalCount + addedNews.length
                                }
                            }
                        }
                        // Initialize new states here, that will be updated
                        const newSearchResultsDoubleMap = {
                            ...searchResultsDoubleMap,
                            '': newSourceFilterToResultsMap
                        };
                        
                        // Update isNew map.
                        const newNewsItemsToTrueMap: NewsItemFeedIdMapToBoolean = {};
                        const newNewsItemsToFalseMap: NewsItemFeedIdMapToBoolean = {};
                        newsThatGetNotification.forEach(newsItem => {
                            const { id } = newsItem;
                            if (id) {
                                newNewsItemsToTrueMap[id] = true;
                                newNewsItemsToFalseMap[id] = false;
                            }
                        });
                        
                        const newNewsItemFeedIdToIsNewMap = {
                            ...newsItemFeedIdToIsNewMap,
                            ...newNewsItemsToTrueMap
                        };
                        const newNewsItemFeedIdToIsHighlightedMap = {
                            ...newsItemFeedIdToIsHighlightedMap,
                            ...newNewsItemsToTrueMap
                        };

                        // Finish by setting all new states and time outs
                        setSearchResultsDoubleMap(newSearchResultsDoubleMap);
                        // Set timeouts for resetting isNew notifier + highlight.
                        setNewsItemFeedIdToIsHighlightedMap(newNewsItemFeedIdToIsHighlightedMap);
                        setNewsItemFeedIdToIsNewMap(newNewsItemFeedIdToIsNewMap);
                        window.setTimeout(() => {
                            const newsItemFeedIdToIsHighlightedMap = newsItemFeedIdToIsHighlightedMapRef.current;
                            setNewsItemFeedIdToIsHighlightedMap({
                                ...newsItemFeedIdToIsHighlightedMap,
                                ...newNewsItemsToFalseMap
                            });
                        }, 15*1000 /*15 sek.*/);
                        window.setTimeout(() => {
                            const newsItemFeedIdToIsNewMap = newsItemFeedIdToIsNewMapRef.current;
                            setNewsItemFeedIdToIsNewMap({
                                ...newsItemFeedIdToIsNewMap,
                                ...newNewsItemsToFalseMap
                            });
                        }, 15*60*1000 /* 15 min. */);
                    },
                    (err) => {
                        setNewsError(err);
                    }
                )
            }
            fetchNewNews(INTIAL_REFRESH_RATE_NEWS_ITEMS_TO_FETCH);
        }
    }
    useEffect(() => {
        if (refreshRateMs) {
            // If refresh rate, set fetchData on an interval
            const intervalId = window.setInterval(() => {
                newNewsRefreshFunction();
            }, refreshRateMs);
            // Clean up: clear interval to avoid memory leaks
            return () => window.clearInterval(intervalId);
        }
    }, [ refreshRateMs ]);

    // Search based fetch.
    useEffect(() => {
        if (usingDefaultNews || dataStatus === 'complete' || dataStatus === 'fully loaded' || usingSourceFilter === 'none') return;

        // Helper delay function.
        const withDelay = (func: () => void) => {
            const timeoutFunctionId = timeoutFunctionIdRef.current;
            if (timeoutFunctionId) window.clearTimeout(timeoutFunctionId);
            timeoutFunctionIdRef.current = window.setTimeout(func, 400);
        }
        
        const searchWord = (usingSearchWord)
            ? searchInput
            : '';
        const sourceFilterString = (usingSourceFilter === 'some')
            ? getSourceStringFromSourceList(sourceFilters, 'on')
            : '';
        if (sourceFilterString === null) return;

        const searchResults = searchResultsDoubleMap?.[searchWord]?.[sourceFilterString];
        if (searchResults && searchResults.results instanceof Error) return;

        const lastResultsLength = (searchResults && Array.isArray(searchResults.results)) ? searchResults.results.length : 0;
        const desiredAmountOfNews = (searchResults && searchResults.totalCount < itemsPerPage) ? searchResults.totalCount : itemsPerPage;

        const fetchNewsFunction = () => fetchNews(
            desiredAmountOfNews,
            (results, totalCount) => {
                const newSearchResultsDoubleMap = updateDoubleMapValue<SearchResultsInfo>(
                    searchResultsDoubleMap,
                    searchWord,
                    sourceFilterString,
                    {
                        results,
                        totalCount,
                        lastResultsLength
                    }
                );
                setSearchResultsDoubleMap(newSearchResultsDoubleMap);
            },
            (err) => {
                const newSearchResultsDoubleMap = updateDoubleMapValue<SearchResultsInfo>(
                    searchResultsDoubleMap,
                    '',
                    sourceFilterString,
                    {
                        results: err,
                        totalCount: 0,
                        lastResultsLength: 0
                    }
                );
                setSearchResultsDoubleMap(newSearchResultsDoubleMap);
            },
            undefined,
            (searchWord === '') ? undefined : searchWord,
            (sourceFilterString === '') ? undefined : sourceFilterString
        )

        if (usingSearchWord) withDelay(fetchNewsFunction);
        else fetchNewsFunction();

    }, [ usingDefaultNews, searchInput, sourceFilters, searchResultsDoubleMap, dataStatus, usingSourceFilter, itemsPerPage, usingSearchWord ]);

    const displayNewsItems = () => {
        const dataOrLastData = dataToDisplay ?? lastDisplayableData ?? null;
        if (dataOrLastData === null) {
            if (newsError === null) return <Loading />;
            else return <ErrorAlert error={newsError} />
        }
        return dataOrLastData.map((feedItem) => (
                <FrettavaktItem
                    key={feedItem.id}
                    item={feedItem}
                    isNew={feedItem.id !== null && (newsItemFeedIdToIsNewMap[feedItem.id] ?? false)}
                    isHighlighted={feedItem.id !== null && (newsItemFeedIdToIsHighlightedMap[feedItem.id] ?? false)}
                    isSeen={feedItem.id !== null && (newsItemFeedIdToIsSeenMap[feedItem.id] ?? false)}
                    onOpen={() => newsItemOnOpen(feedItem)}
                />
        ))
    }

    const getMoreButtonText = () => {
        if (usingSourceFilter === 'none') return 'Hætt að sækja. Veldu fleiri veitur.';
        if (dataStatus === 'no data' || dataStatus === 'incomplete') return 'Hleður...';
        return 'Sýna fleiri fréttir';
    }

    const displaySources = () => {
        if (sourceFilters === null) return <Loading />;
        if (sourceFilters instanceof Error) return <ErrorAlert error={sourceFilters} />;
        const showCategory = newsFeedCategoryDetails.category === 'is_bus';


        let uniqueCategories: (string | undefined)[] = [];
        let sourcesByCategory: { category: string | undefined; sources: INewsFeedSourceFilter[] }[] = [];
        
        if (showCategory) {
            // get unique categories
            uniqueCategories = sourceFilters.map(({newsFeedSource}) => newsFeedSource.feed.feedType).filter((value, index, self) => self.indexOf(value) === index);
            // sort categories
            const categoryOrder = ["news", "article", "press_release", "exchange_notice", undefined];
            
            // Sort the categories based on the defined order
            uniqueCategories.sort((a, b) => {
                return categoryOrder.indexOf(a) - categoryOrder.indexOf(b);
            });
    
            //for every category, fill it with sources for that type
            sourcesByCategory = uniqueCategories.map(category => {
                return {
                    category,
                    sources: sourceFilters.filter(({ newsFeedSource }) => newsFeedSource.feed.feedType === category)
                };
            });
        }
    
        const getCategoryIcon = (items: INewsFeedSourceFilter[]): IconDefinition => {
            if (items.every(item => item.isOn)) return faCircleCheck;
            if (items.every(item => !item.isOn)) return faCircle;
            return faCircleMinus; // no need to check some() - this is the only remaining case
        }
    
        return (
            <>
                <div className={cx('source-filters', { 'source-filters--category': showCategory })}>
                    {
                    //display velja allt button if not business category
                    (!showCategory)
                     && <FilterItem
                         showCheck
                         size='lg'
                         text="Velja allt"
                         selected={usingSourceFilter === 'all'}
                         toggleSelected={() => {
                             const sourcesCopy: INewsFeedSourceFilter[] = sourceFilters.map(sourceFilter => ({
                                newsFeedSource: sourceFilter.newsFeedSource,
                                isOn: usingSourceFilter !== 'all'
                             }));
                             // Save current settings to local storage.
                             const localStorageSettingsString = getSourceStringFromSourceList(sourcesCopy, 'off');
                             try {
                                 if (localStorageSettingsString !== null) {
                                     localStorage.setItem(localStorageKey, localStorageSettingsString);
                                 } else {
                                     localStorage.removeItem(localStorageKey);
                                 }
                             } catch (e) {
                                 // Local Storage Error.
                             }
                             setSourceFilters(sourcesCopy);
                         }}
                     />
                    }
                    {
                    showCategory
                    ? sourcesByCategory.map((category) => 
                    <div
                        style={{cursor: category.category === activeCategory ? 'default' : 'pointer'}}
                        className={cx('category-wrapper', {'is-open': category.category === activeCategory})}
                        key={category.category} 
                        onClick={(e) => {
                            if(activeCategory === category.category) e.stopPropagation();
                            else setActiveCategory(category.category)
                        }}>
                        <div className="category-item">
                            <FontAwesomeIcon
                                className='fa-icon'
                                icon={getCategoryIcon(category.sources)}
                                // if at least one selected -> deselect all, else select all
                                onClick={(e) => {
                                    e.stopPropagation();
                                    const sourcesCopy: INewsFeedSourceFilter[] = sourceFilters.map(sourceFilter => {
                                        if (sourceFilter.newsFeedSource.feed.feedType === category.category) {
                                            return {
                                                newsFeedSource: sourceFilter.newsFeedSource,
                                                isOn: category.sources.every(source => !source.isOn )
                                            }
                                        }
                                        return sourceFilter;
                                    });
                                    // Save current settings to local storage.
                                    const localStorageSettingsString = getSourceStringFromSourceList(sourcesCopy, 'off');
                                    try {
                                        if (localStorageSettingsString !== null) {
                                            localStorage.setItem(localStorageKey, localStorageSettingsString);
                                        }
                                        else {
                                            localStorage.removeItem(localStorageKey);
                                        }
                                    } catch (e) {
                                        // Local Storage Error.
                                    }
                                    setSourceFilters(sourcesCopy);
                                    }
                                    }
                                />
                            <div
                                className='category-header'
                                onClick={() => {
                                    if(activeCategory === category.category) setActiveCategory(undefined);
                                    else setActiveCategory(category.category)
                                    }}>
                                    <div>
                                        <span className='category-name'>{`${getNewsTypeCategories(category.category as is_bus_categories)}`}</span>
                                        <span className='category-length'>{`(${category.sources.length})`}</span>
                                    </div>
                                    <FontAwesomeIcon 
                                        className={`fa-icon ${activeCategory === category.category ? 'rotate-open' : ''}`}
                                        icon={faAngleDown} 
                                    />
                            </div>
                        </div>
                        <AnimateHeight
                            className="category-item__content"
                            duration={300}
                            height={activeCategory === category.category ? 'auto' : 0}
                        >
                            <div className='category-item__body'>
                                {category.sources.map(({newsFeedSource, isOn}) => (
                                    <FilterItem
                                        size={"lg"}
                                        showCheck
                                        text={newsFeedSource.feed.feedDisplayName}
                                        selected={isOn}
                                        toggleSelected={() => {
                                            
                                            const sourcesCopy = [...sourceFilters];
                                            const index = sourcesCopy.findIndex(({newsFeedSource: source}) => source.feed.feedKey === newsFeedSource.feed.feedKey);
                                            sourcesCopy[index] = {
                                                newsFeedSource,
                                                isOn: !isOn
                                            };
                                            // Save current settings to local storage.
                                            const localStorageSettingsString = getSourceStringFromSourceList(sourcesCopy, 'off');
                                            try {
                                                if (localStorageSettingsString !== null) {
                                                    localStorage.setItem(localStorageKey, localStorageSettingsString);
                                                } else {
                                                    localStorage.removeItem(localStorageKey);
                                                }
                                            } catch (e) {
                                                // Local Storage Error.
                                            }
                                            setSourceFilters(sourcesCopy);
                                        }
                                    }
                                    />
                                ))}
                            </div>
                        </AnimateHeight>
                    </div>
                    )
                    : sourceFilters.map(({newsFeedSource, isOn }, i) =>
                        <FilterItem
                            key={newsFeedSource.feed.feedKey}
                            showCheck
                            size='lg'
                            selected={isOn}
                            text={newsFeedSource.feed.feedDisplayName}
                            toggleSelected={() => {
                                // Copy to prevent corruption of state.
                                const sourcesCopy = [...sourceFilters];
                                sourcesCopy.splice(i, 1, {
                                    newsFeedSource,
                                    isOn: !isOn
                                });
                                // Save current settings to local storage.
                                const localStorageSettingsString = getSourceStringFromSourceList(sourcesCopy, 'off');
                                try {
                                    if (localStorageSettingsString !== null) {
                                        localStorage.setItem(localStorageKey, localStorageSettingsString);
                                    } else {
                                        localStorage.removeItem(localStorageKey);
                                    }
                                } catch (e) {
                                    // Local Storage Error.
                                }
                                setSourceFilters(sourcesCopy);
                            }}
                        />
                    )}
                </div>
                <div className='vaktarinn-wrapper'>
                    <img style={{width: '200px'}} src="https://cdn.livemarketdata.com/KeldanImages/VaktarinnLogo.svg" alt="Vaktarinn" />
                    <p className='vaktarinn-description paragraph-small'>Þarft þú ítarlegri fréttavöktun? Tilkynningar Vaktarans gera þér kleift að bregðast við umræðu um fyrirtækið þitt á meðan hún á sér stað.</p>
                    <div className='vaktarinn-button-wrapper'>
                        <Button
                            buttonType="secondary"
                            size="sm"
                            anchorProps={{
                                href: 'https://portal.vaktarinn.is/login',
                                target: '_blank',
                                rel: 'noopener noreferrer'
                            }}
                        >
                            Innskráning í Vaktarann
                        </Button>
                        <Button
                            buttonType="primary"
                            size="sm"
                            anchorProps={{href: '/Vaktarinn#askrift'}}
                        >
                            Áskrift að Vaktaranum
                        </Button>
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className="KCL_frettavakt-container">
            <div className="row">
                <div className="col-lg-8 main-content-wrapper">
                    {/* Search Input */}
                    <div className="search__box">
                        <div className='input-and-filter'>
                            <SmallSearch
                                search={searchInput}
                                setSearch={setSearchInput}
                                id={`Search_News_NewsPage_${newsFeedCategoryDetails.category}`}
                            />
                            <div className='filter-wrapper'>
                                {(sourceFilters === null || sourceFilters instanceof Error)
                                    ? null
                                    : <Filter
                                        heading='Veitur'
                                        itemStyle={{
                                            size: 'lg',
                                            showCheck: true
                                        }}
                                        sortByCategory={newsFeedCategoryDetails.category === 'is_bus'}
                                        notAllSelectedBubble
                                        itemValues={sourceFilters.map(({newsFeedSource, isOn }, i) => ({
                                            selected: isOn,
                                            text: newsFeedSource.feed.feedDisplayName,
                                            key: newsFeedSource.feed.feedKey,
                                            type: newsFeedCategoryDetails.category === 'is_bus' ? newsFeedSource.feed.feedType : undefined,

                                            toggleSelected: () => {
                                                // Copy to prevent corruption of state.
                                                const sourcesCopy = [...sourceFilters];
                                                sourcesCopy.splice(i, 1, {
                                                    newsFeedSource,
                                                    isOn: !isOn
                                                });
                                                // Save current settings to local storage.
                                                const localStorageSettingsString = getSourceStringFromSourceList(sourcesCopy, 'off');
                                                try {
                                                    if (localStorageSettingsString !== null) {
                                                        localStorage.setItem(localStorageKey, localStorageSettingsString);
                                                    } else {
                                                        localStorage.removeItem(localStorageKey);
                                                    }
                                                } catch (e) {
                                                    // Local Storage Error.
                                                }
                                                setSourceFilters(sourcesCopy);
                                            }
                                        }))}
                                        columnStyle={newsFeedCategoryDetails.category === 'is_bus' ? 'categories' : 'flow'}
                                        toggleAll={(category?: IValueProps[]) => {
                                                //check if some are selected
                                                const catStatus = category?.some(cat => cat.selected);
                                                //if some are selected -> make deselected for that category
                                                //else make all selected for that category

                                                // Original source filter logic
                                                const sourcesCopy: INewsFeedSourceFilter[] = sourceFilters.map(sourceFilter => ({
                                                    newsFeedSource: sourceFilter.newsFeedSource,
                                                    isOn: category ? 
                                                    sourceFilter.newsFeedSource.feed.feedType === category?.[0].type
                                                    ? catStatus 
                                                        ? false 
                                                        : true
                                                    : sourceFilter.isOn
                                                    : usingSourceFilter !== 'all'
                                                }));
                                                
                                                // Save current settings to local storage.
                                                const localStorageSettingsString = getSourceStringFromSourceList(sourcesCopy, 'off');
                                                try {
                                                    if (localStorageSettingsString !== null) {
                                                        localStorage.setItem(localStorageKey, localStorageSettingsString);
                                                    } else {
                                                        localStorage.removeItem(localStorageKey);
                                                    }
                                                } catch (e) {
                                                    // Local Storage Error.
                                                }
                                                setSourceFilters(sourcesCopy);
                                        }}
                                    />
                                }
                            </div>
                        </div>
                    </div>

                    {/* List of news items */}
                    {displayNewsItems()}

                    {/* "Get more news" Button */}
                    <div className='load-more-button-wrapper'>
                        {(dataStatus !== 'fully loaded') && (
                            <Button
                                size='lg'
                                buttonType='secondary'
                                onClick={() => {
                                    setItemsPerPage(itemsPerPage + ITEMS_TO_DISPLAY_PER_FETCH)
                                }}
                                disabled={dataStatus !== 'complete' || usingSourceFilter === 'none'}
                            >
                                {getMoreButtonText()}
                            </Button>
                        )}
                    </div>
                </div>
                <div className="col-lg-4 source-filter-wrapper">
                    <AdRotator location='FrontPage400x80' />
                    <h3 className='veitur'>Veitur</h3>
                    {displaySources()}
                </div>
                <div className='vaktarinn-wrapper tabletView'>
                    <img style={{width: '200px'}} src="https://cdn.livemarketdata.com/KeldanImages/VaktarinnLogo.svg" alt="Vaktarinn" />
                    <p className='vaktarinn-description paragraph-small'>Þarft þú ítarlegri fréttavöktun? Tilkynningar Vaktarans gera þér kleift að bregðast við umræðu um fyrirtækið þitt á meðan hún á sér stað.</p>
                    <div className='vaktarinn-button-wrapper'>
                        <Button
                            buttonType="secondary"
                            size="sm"
                            anchorProps={{
                                href: 'https://portal.vaktarinn.is/login',
                                target: '_blank',
                                rel: 'noopener noreferrer'
                            }}
                        >
                            Innskráning í Vaktarann
                        </Button>
                        <Button
                            buttonType="primary"
                            size="sm"
                            anchorProps={{href: '/Vaktarinn#askrift'}}
                        >
                            Áskrift að Vaktaranum
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FrettavaktContainer;