// Package imports:
import React, { useEffect, useMemo, useState } from 'react';
// Component imports:
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import CompanyReportsChart from '../../Charts/CompanyReportsChart/CompanyReportsChart';
import Tabs from '../../../ui-elements/Tabs/Tabs';
import Fyrirvari from '../../Fyrirvari/Fyrirvari';
import Link from '../../../ui-elements/Link/Link';
import LockIcon from '../../../ui-elements/LockIcon/LockIcon';
import SubscriptionBanner from '../../SubscriptionBanner/SubscriptionBanner';
// Service imports:
import { GET_API_LMD_URL } from '../../../services/config';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { CompanyInfo, ICompanyViewModel } from '../../../types/CompanyTypes';
import { Fetched } from '../../../types/Types';


const CompanyReportsChartWrapper: React.FC<ICompanyViewModel> = ({
    id,
    isSubscriber,
    accessToken
}) => {
    const [disclaimerOpen, setDisclaimerOpen] = useState(false);
    const openDisclaimer = () => setDisclaimerOpen(true);
    const closeDisclaimer = () => setDisclaimerOpen(false);

    useEffect(() => {
        if (isSubscriber && accessToken)
            fetchCompanyInfoData();
    }, [accessToken, isSubscriber]);

    const [companyInfoData, setCompanyInfoData] = useState<Fetched<CompanyInfo>>();

    const fetchCompanyInfoData = async () => {
        try {
            const url = `${GET_API_LMD_URL()}/v1/market_data/v1/company_reports/company_info_by_ssn?ssn=${id}`;
            const headers = {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${accessToken}`
            };
            
            const response = await fetch(url, {
                method: 'GET',
                headers
            });
            if (response.ok) {
                const responseBody: CompanyInfo[] = await response.json();
                setCompanyInfoData(responseBody.length === 0 ? null : responseBody[0]);
            } else {
                setCompanyInfoData(new Error(ErrorMessages.RequestFailed));
            }
        } catch (e) {
            setCompanyInfoData(new Error(ErrorMessages.NetworkError));
        }
    }

    const companyInfoID = useMemo(() => {
        if (companyInfoData instanceof Error)
            return undefined;
        if (companyInfoData === null)
            return null;
        return companyInfoData?.Id;
    }, [companyInfoData]);

    return (
        <DisplayBox 
            className='KCL_company-reports-chart'
            titleComponent={
                <>
                    {!isSubscriber && <LockIcon/>}
                    Lykiltölur úr ársreikningum
                </>
            }
            footerRight={
                <Fyrirvari
                    show={disclaimerOpen}
                    open={openDisclaimer}
                    close={closeDisclaimer}
                >
                    <p className='paragraph'>
                        Keldan vinnur gögn úr opinberum upplýsingum eftir bestu getu. 
                        Keldan ber ekki ábyrgð á röngum upplýsingum sem geta komið til vegna innsláttarvillna eða annarra þátta.
                        Ábendingar um villur eða rangar upplýsingar eru vel þegnar með tölvupósti á <Link linkSize='18' url='mailto:help@keldan.is'>help@keldan.is.</Link>
                    </p>
                </Fyrirvari>
            }
        >
            <Tabs
                tabs={[{
                    id: 'incomeStatement',
                    label: 'Rekstur',
                    component: (isSubscriber)
                        ? <CompanyReportsChart
                            companyInfoID={companyInfoID}
                            chartType='incomeStatement'
                            accessToken={accessToken}
                        />
                        : <SubscriptionBanner size='sm'/>
                }, {
                    id: 'balanceSheet',
                    label: 'Efnahagur',
                    component: (isSubscriber)
                        ? <CompanyReportsChart
                            companyInfoID={companyInfoID}
                            chartType='balanceSheet'
                            accessToken={accessToken}
                        />
                        : <SubscriptionBanner size='sm'/>
                }, {
                    id: 'cashFlow',
                    label: 'Sjóðstreymi',
                    component: (isSubscriber)
                        ? <CompanyReportsChart
                            companyInfoID={companyInfoID}
                            chartType='cashFlow'
                            accessToken={accessToken}
                        />
                        : <SubscriptionBanner size='sm'/>
                }]}
            />
        </DisplayBox>
    );
}

export default CompanyReportsChartWrapper;