// Package imports:
import React, { useState } from "react";
import { SwiperSlide } from "swiper/react";
// Component imports:
import Slider from "../Slider/Slider";
import FullScreenImage from "../../ui-elements/FullScreenImage/FullScreenImage";
import TextImageBlock from "../TextImageBlock/TextImageBlock";
import Button from "../../ui-elements/Button/Button";
import HeadSection from "../../ui-elements/HeadSection/HeadSection";
import MarkadirPricing from "../KeldanMarkadirPricingSection/KeldanMarkadirPricingSection";
import Link from "../../ui-elements/Link/Link";

const KeldanMarkadirPage: React.FC = () => {
    const [fullScreenImageUrl, setFullScreenImageUrl] =
        useState<string | null>(null);
    return (
        <div className="main KCL_keldan-markadir-page">
            {fullScreenImageUrl && (
                <FullScreenImage
                    imgUrl={fullScreenImageUrl}
                    imgAlt="Fullscreen Keldan App screenshot"
                    onExit={() => setFullScreenImageUrl(null)}
                />
            )}
            <div className="main__inner-fluid grid-items">
                <div className="shell">
                    <HeadSection
                        title={
                            <object
                                data="https://cdn.livemarketdata.com/KeldanImages/Keldan_markadir_logo.svg"
                                aria-label="Keldan | markaðir"
                            >
                                Keldan | markaðir
                            </object>
                        }
                        summary={<p>Vertu með sömu sýn á markaðinn og verðbréfamiðlarar</p>}
                        actions={
                            <>
                            <Button
                                buttonType="secondary"
                                anchorProps={{
                                href: "https://markadir.keldan.is/login",
                                }}
                            >
                                Innskráning
                            </Button>
                            <Button
                                buttonType="primary"
                                //scroll to section id="Askriftarleidir"
                                onClick={() =>
                                document
                                    .getElementById("Askriftarleidir")
                                    ?.scrollIntoView({ behavior: "smooth" })
                                }
                            >
                                Áskrift
                            </Button>
                            </>
                        }
                        textAlign="center"
                    />
                </div>
                <div className="section__body">
                    <div className="shell">
                        <TextImageBlock
                            imgSrc="https://cdn.livemarketdata.com/KeldanImages/Keldan_markadir_cover_image.png"
                            imgSide="right"
                        >
                            <h2 id="KeldanApp_Summary">Markaðurinn í rauntíma</h2>
                            <div className="text-image-block__entry">
                                <p className="paragraph--bold">
                                    Keldan markaðir er vefur og app sem veitir viðskiptavinum
                                    beinan aðgang að Kauphöll Íslands.
                                </p>
                                <p className="paragraph">
                                    Vefurinn er sérstaklega hannaður fyrir fjárfesta og þá sem
                                    þurfa meiri og betri upplýsingar en þær sem eru í boði á opnum
                                    vef Keldunnar. Á vefnum eru allar upplýsingar í rauntíma.
                                </p>
                                <p className="paragraph">
                                    Appið er notað af þúsundum Íslendinga á degi hverjum til þess
                                    að fylgjast með því sem er að gerast á markaðnum hverju sinni.
                                    Appið er hægt að fá með rauntímagögnum eða með 15 mínútna
                                    seinkuðum gögnum.
                                </p>
                            </div>
                            <Link
                                url="https://kodihq.atlassian.net/wiki/spaces/KMV/pages/3101884417/User+guide"
                                linkSize="16"
                                icon="forward"
                                targetBlank
                            >
                                Sjá notendahandbók Keldan markaðir
                            </Link>
                        </TextImageBlock>
                    </div>
                </div>
                <div id="Askriftarleidir" className="section--gray section--subscription">
                    <MarkadirPricing page='info' />
                </div>
                <AppScreenshotsSection setFullScreenImageUrl={setFullScreenImageUrl} />
                <DesktopScreenshotsSection
                    setFullScreenImageUrl={setFullScreenImageUrl}
                />
            </div>
        </div>
    );
};

export default KeldanMarkadirPage;

const AppScreenshotsSection: React.FC<{
    setFullScreenImageUrl: (e: string) => void;
}> = ({ setFullScreenImageUrl }) => {
    const KELDAN_APP_IMAGES_URLS = [
        "https://cdn.livemarketdata.com/KeldanImages/KeldanAppExample1.png",
        "https://cdn.livemarketdata.com/KeldanImages/KeldanAppExample2.png",
        "https://cdn.livemarketdata.com/KeldanImages/KeldanAppExample3.png",
        "https://cdn.livemarketdata.com/KeldanImages/KeldanAppExample4.png",
        "https://cdn.livemarketdata.com/KeldanImages/KeldanAppExample5.png",
        "https://cdn.livemarketdata.com/KeldanImages/KeldanAppExample6.png",
        "https://cdn.livemarketdata.com/KeldanImages/KeldanAppExample7.png",
        "https://cdn.livemarketdata.com/KeldanImages/KeldanAppExample8.png",
        "https://cdn.livemarketdata.com/KeldanImages/KeldanAppExample9.png",
        "https://cdn.livemarketdata.com/KeldanImages/KeldanAppExample10.png",
        "https://cdn.livemarketdata.com/KeldanImages/KeldanAppExample11.png",
        "https://cdn.livemarketdata.com/KeldanImages/KeldanAppExample12.png",
        "https://cdn.livemarketdata.com/KeldanImages/KeldanAppExample13.png",
        "https://cdn.livemarketdata.com/KeldanImages/KeldanAppExample14.png",
        "https://cdn.livemarketdata.com/KeldanImages/KeldanAppExample15.png",
        "https://cdn.livemarketdata.com/KeldanImages/KeldanAppExample16.png",
    ];

    return (
        <div className="section screenshotSection">
            <div className="section__head">
                <div className="shell">
                    <h2 id="KeldanApp_Screenshots">Skjáskot úr Keldu appinu</h2>
                </div>
            </div>
            <div className="section__body">
                <div className="shell">
                    <Slider
                        className="slider-screenshots"
                        perView={5}
                        centerSlides={false}
                        loop={true}
                        breakpointOptions={{
                            320: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 3,
                            },
                            1440: {
                                slidesPerView: 5,
                            },
                        }}
                        onInit={() => {}}
                        onSlideChange={() => {}}
                    >
                        {KELDAN_APP_IMAGES_URLS.map((url) => (
                            <SwiperSlide key={url} onClick={() => setFullScreenImageUrl(url)}>
                                <div>
                                    <img
                                        src={url}
                                        alt="Keldan App screenshot"
                                        className="slide-image"
                                        width="1080"
                                        height="2340"
                                    />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
};
const DesktopScreenshotsSection: React.FC<{
    setFullScreenImageUrl: (e: string) => void;
}> = ({ setFullScreenImageUrl }) => {
    const KELDAN_MARKADIR_IMAGES_URLS = [
        "https://cdn.livemarketdata.com/KeldanImages/Keldan_markadir_desktop_screenshot1.png",
        "https://cdn.livemarketdata.com/KeldanImages/Keldan_markadir_desktop_screenshot2.png",
    ];
    return (
        <div className="section screenshotSection desktop-screenshot-section">
            <div className="section__head">
                <div className="shell">
                    <h2 id="KeldanMarkadir_Screenshots">
                        Skjáskot frá Keldan markaðir veflausn
                    </h2>
                </div>
            </div>
            <div className="section__body">
                <div className="shell">
                    <Slider
                        className="slider-screenshots"
                        perView={2}
                        centerSlides={false}
                        loop={false}
                        hideActions={true}
                        breakpointOptions={{
                            400: {
                                slidesPerView: 1,
                                centerSlides: false,
                            },
                            768: {
                                slidesPerView: 2,
                                centerSlides: true,
                            },
                        }}
                        onInit={() => {}}
                        onSlideChange={() => {}}
                    >
                        {KELDAN_MARKADIR_IMAGES_URLS.map((url) => (
                            <SwiperSlide key={url} onClick={() => setFullScreenImageUrl(url)}>
                                <img
                                    src={url}
                                    alt="Keldan markadir screenshot"
                                    className="desktopScreenshots"
                                />
                            </SwiperSlide>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
};
