// Package imports:
import React from "react";
// Component imports:
import Entrypoint from "../Entrypoint";
import PropertyPage from "../../components/Property/PropertySearches";
// Type imports:
import { IPropertySearchViewModel } from "../../types/PropertyTypes";
import { HistoryRefreshProvider } from "../../contexts/HistoryRefreshContext";

const PropertySearches: React.FC<IPropertySearchViewModel> = ({
  isAuthenticated,
  bondsAllowed,
  individualAssetsAllowed,
  documentsAllowed,
}) => {
  return (
    <Entrypoint>
      <HistoryRefreshProvider>
        <PropertyPage
          isAuthenticated={isAuthenticated ?? false}
          bondsAllowed={bondsAllowed ?? false}
          individualAssetsAllowed={individualAssetsAllowed ?? false}
          documentsAllowed={documentsAllowed ?? false}
        />
      </HistoryRefreshProvider>
    </Entrypoint>
  );
};

export default PropertySearches;
