// Package imports:
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
import { SwiperSlide } from "swiper/react";
// Component imports:
import Tabs from "../../ui-elements/Tabs/Tabs";
import Tooltip from "../../ui-elements/Tooltip/Tooltip";
import Slider from "../Slider/Slider";
import PriceCard from "../PriceCard/PriceCard";
import Button from "../../ui-elements/Button/Button";
import CheckList from "../../ui-elements/Lists/CheckList";
import DisplayBox from "../../ui-elements/DisplayBox/DisplayBox";
import Link from "../../ui-elements/Link/Link";
// Service imports:
import { gtmPush } from "../../services/utils";

const KeldanMarkadirPricingSection: React.FC<{page: 'info' | 'pricing'}> = ({page}) => {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <div
            id="Markadir-pricing"
            className="section section--subscription mini_shell"
        >
            <div className="section__head">
                {page === 'info'
                    ? <>
                        <h2 id="KeldanApp_Subscriptions">Áskriftarleiðir</h2>
                        <p className="summary-paragraph">
                            Keldan býður upp á nokkrar áskriftarleiðir til að mæta ólíkum
                            þörfum viðskiptavina.
                            <br />
                            Athugið að öll verð eru birt án virðisaukaskatts.
                        </p>
                    </>
                    : <h2 id="KeldanApp_Subscriptions">Keldan markaðir - áskriftarleiðir</h2>
                }
            </div>
            <div className="section__body section--subscription">
                    <div>
                        <Tabs
                            tabsSize="lg"
                            onTabChange={(e) => setActiveTab(e)}
                            tabs={[{
                                id: "almennir",
                                label: "Almennir fjárfestar",
                                component: <KeldanMarkadirSlidersAlmennirFjarfestar />,
                            }, {
                                id: "fagadilar",
                                label: "Fagaðilar",
                                component: <KeldanMarkadirSlidersFagadilarFjarfestar />,
                            }]}
                            saveTabToUrl
                        />
                        {/* APP STORE SECTION */}
                        <div className="section--subscription section--appStoreLinks">
                            <p className="paragraph--bold">Þú sækir Keldu appið hér:</p>
                            <div className="app-download-links">
                                <a
                                    className="google-play"
                                    href="https://play.google.com/store/apps/details?id=is.kodi.keldanMobile"
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={() =>
                                        gtmPush(
                                            "buttonClick",
                                            "download_app",
                                            "link_to_store",
                                            "google_play"
                                        )
                                    }
                                    aria-label="Keldu appið á Google Play Store"
                                >
                                    <img
                                        src="https://cdn.livemarketdata.com/Images/google-play.svg"
                                        alt="Google play store logo"
                                        width="132"
                                        height="39"
                                    />
                                </a>
                                <a
                                    className="app-store"
                                    href="https://apps.apple.com/is/app/keldan-app/id1162095731"
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={() =>
                                        gtmPush(
                                            "buttonClick",
                                            "download_app",
                                            "link_to_store",
                                            "app_store"
                                        )
                                    }
                                    aria-label="Keldu appið á Apple App Store"
                                >
                                    <img
                                        src="https://cdn.livemarketdata.com/Images/app-store.svg"
                                        alt="Apple app store logo"
                                        width="132"
                                        height="44"
                                    />
                                </a>
                            </div>
                            <div className="price-card-notice appStoreNoticeText">
                                <p
                                    className="paragraph-small"
                                    style={{ maxWidth: "535px" }}
                                >
                                    {activeTab === 0
                                        ? "Almennur fjárfestir er einstaklingur eða lögaðili sem ekki flokkast sem fagaðili, fagfjárfestir eða viðurkenndur gagnaðili."
                                        : "Fagaðilar, fagfjárfestar og viðurkenndir gagnaðilar. Einstaklingar sem starfa sem fagaðilar til dæmis við verðbréfamiðlun eða fjárfestingaráðgjöf."
                                    }
                                </p>
                            </div>
                            <Link
                                url="/Keldan-Markadir/Skilmalar"
                                linkSize="16"
                                icon="forward"
                            >
                                Lesa skilmála Keldan markaðir
                            </Link>
                        </div>
                    </div>
                    <KeldanMarkadirVerdskra activeTab={activeTab} />
            </div>
        </div>
    )
}

const KeldanMarkadirVerdskra: React.FC<{ activeTab: number }> = ({
    activeTab,
}) => {
    const PRICES =
        activeTab === 0
        ? [{
            //ALMENNIR
            category: "Hlutabréf og skuldabréf",
            description: "Hagstæðasta kaup- og sölutilboð",
            price: "2€",
        }, {
            category: "Hlutabréf og skuldabréf",
            description: "Hagstæðustu 5 kaup- og sölutilboð",
            price: "10€",
        }, {
            category: "Hlutabréf og skuldabréf",
            description: "Öll kaup- og sölutilboð",
            price: "16€",
        }]
        : [{
            //FAGAÐILAR
            category: "Hlutabréf",
            description: "Hagstæðasta kaup- og sölutilboð",
            price: "58€",
        }, {
            category: "Hlutabréf",
            description: "Hagstæðustu 5 kaup- og sölutilboð",
            price: "98€",
        }, {
            category: "Hlutabréf",
            description: "Öll kaup- og sölutilboð",
            price: "125€",
        }, {
            category: "Skuldabréf",
            description: "Öll kaup- og sölutilboð",
            price: "85€",
        }];
    return (
        <div id="Verdskra" className="price-table-section">
            <DisplayBox
                title="Verðskrá fyrir rauntímagögn"
                displayBoxSize="xl"
            >
                <p className="paragraph--italic">
                    Öll verð eru sýnd án virðisaukaskatts
                </p>
                <h6>{activeTab === 0 ? "Almennir fjárfestar" : "Fagaðilar"}</h6>
                <div className="price_list">
                    {PRICES.map((item, index) => (
                        <div className="price_list_item" key={index}>
                            <p className="paragraph-small">
                                <span className="paragraph-small--bold">
                                    Leið {index + 1}: {item.category}
                                </span>
                                <span>{' - ' + item.description}</span>
                            </p>
                            <div className="price">
                                <span className="paragraph--bold">{item.price}</span>
                                <span className="paragraph-small per_month">á mánuði</span>
                            </div>
                        </div>
                    ))}
                </div>
            </DisplayBox>
        </div>
    );
};
const KeldanMarkadirSlidersAlmennirFjarfestar: React.FC = () => {
    return (
        <Slider
            perView={3}
            centerSlides={true}
            loop={false}
            hideActions={true}
            initialSlide={1}
            // spaceBetween={26}
            breakpointOptions={{
                320: {
                    slidesPerView: 1,
                    noSwiping: false,
                    initialSlide: 2
                },
                768: {
                    slidesPerView: 2,
                    noSwiping: false,
                },
                1024: {
                    slidesPerView: 3,
                    noSwiping: true,
                },
            }}
            onSlideChange={() => {}}
            onInit={() => {}}
            className="price-cards__slider swiper-no-swiping"
        >
            <SwiperSlide>
                {/* FIRST PRICE CARD */}
                <PriceCard
                    keldanMarkadirPriceCard
                    type="Frír aðgangur að appi"
                    key="basic"
                >
                    <h6 className="description">
                        Frí áskrift inniheldur aðgang að Keldu appinu með seinkuðum gögnum
                    </h6>
                    <CheckList
                        items={["15 mínútna seinkun á markaðsgögnum.", "Viðskiptafréttir."]}
                        size="sm"
                    />
                </PriceCard>
            </SwiperSlide>
            <SwiperSlide>
                {/* SECOND PRICE CARD */}
                <PriceCard
                    keldanMarkadirPriceCard
                    variant="primary"
                    type="App"
                    key="individual"
                >
                    <div className="storePriceContainer">
                        <div className="storePriceWrapper">
                            <span className="storePriceWrapper__price">
                                <strong>19.83</strong>
                                <span className="storePriceWrapper__currency">USD</span>
                            </span>
                            <span className="storePriceWrapper__store">
                                <strong>App Store á mánuði</strong>
                            </span>
                        </div>
                        {/* Vertical line */}
                        <div className="vertical_line"></div>
                        <div className="storePriceWrapper">
                            <span className="storePriceWrapper__price">
                                <strong>19.99</strong>
                                <span className="storePriceWrapper__currency">USD</span>
                            </span>
                            <span className="storePriceWrapper__store">
                                <strong>Google Play á mánuði</strong>
                            </span>
                        </div>
                    </div>
                    <h6
                        className="description"
                        style={{
                        color: "white",
                        marginRight: "-2px",
                        }}
                    >
                        Aðgangur að rauntímagögnum í appinu
                        <Disclaimer type="App" />
                    </h6>
                    <CheckList
                        items={[
                        "Markaðsgögn í rauntíma.",
                        "Yfirlit yfir viðskipti.",
                        "Tilkynningar og rauntímavakt á hlutabréf að eigin vali.",
                        ]}
                        size="sm"
                    />
                </PriceCard>
            </SwiperSlide>
            <SwiperSlide>
                {/* THIRD PRICE CARD */}
                <PriceCard
                    variant="secondary"
                    type="Vefur og app"
                    key="individual"
                    noPriceLabel
                    keldanMarkadirPriceCard
                >
                    <div className="price-card__price">
                        <p>
                            <strong>3.280</strong>kr. á mánuði + VSK
                        </p>
                    </div>
                    <span className="small-disclaimer">
                        Ásamt mánaðargjaldi Nasdaq OMX*
                    </span>
                    <h6 className="description" style={{ color: "white" }}>
                        Aðgangur að <a href="https://markadir.keldan.is">Keldan markaðir</a>{" "}
                        vef og appi ásamt rauntímagögnum eftir þörfum
                        <Disclaimer type="Sub" />
                    </h6>
                    <CheckList
                        items={[
                            "Markaðsgögn í rauntíma.",
                            "Yfirlit yfir viðskipti.",
                            "Tilkynningar og rauntímavakt.",
                            "Tilkynningar og rauntímavakt í appinu",
                        ]}
                        size="sm"
                    />
                    <div className="price-card__actions">
                        <Button
                            buttonType="primary"
                            anchorProps={{
                                href: `/Hafa-Samband/Keldan-Markadir-Prufa`,
                            }}
                        >
                            Fá prufuaðgang
                        </Button>
                        <Button
                            buttonType="buy"
                            anchorProps={{
                                href: `/Hafa-Samband/Keldan-Markadir`,
                            }}
                        >
                            Kaupa áskrift
                        </Button>
                    </div>
                </PriceCard>
                <div className="price-card-notice">
                    <p className=" paragraph-small--italic">*Sjá verðskrá fyrir neðan.</p>
                </div>
            </SwiperSlide>
        </Slider>
    );
};

const KeldanMarkadirSlidersFagadilarFjarfestar: React.FC = () => {
    return (
        <Slider
            perView={3}
            centerSlides={true}
            loop={false}
            hideActions={true}
            initialSlide={1}
            breakpointOptions={{
                320: {
                    slidesPerView: 1,
                    noSwiping: false,
                    initialSlide: 2
                },
                768: {
                    slidesPerView: 2,
                    noSwiping: false,
                },
                1024: {
                    slidesPerView: 3,
                    noSwiping: true,
                },
            }}
            onSlideChange={() => {}}
            onInit={() => {}}
            className="price-cards__slider swiper-no-swiping"
        >
            <SwiperSlide>
                {/* FIRST PRICE CARD */}
                <PriceCard
                    keldanMarkadirPriceCard
                    type="Frír aðgangur að appi"
                    key="basic"
                >
                    <h6 className="description">
                        Frí áskrift inniheldur aðgang að Keldu appinu með seinkuðum gögnum
                        líkt og er á opnum vef Keldunnar
                    </h6>
                    <CheckList
                        items={["15 mínútna seinkun á markaðsgögnum.", "Viðskiptafréttir."]}
                        size="sm"
                    />
                </PriceCard>
            </SwiperSlide>
            <SwiperSlide>
                {/* SECOND PRICE CARD */}
                <PriceCard
                    keldanMarkadirPriceCard
                    variant="primary"
                    type="App"
                    key="individual"
                >
                    <div className="price-card__price">
                        <p>
                            <strong>3.280</strong>kr. á mánuði + VSK
                        </p>
                    </div>
                    <span className="small-disclaimer">
                        Ásamt mánaðargjaldi Nasdaq OMX*
                    </span>
                    <h6
                        className="description"
                        style={{
                            color: "white",
                            marginRight: "-2px",
                        }}
                    >
                        Aðgangur að rauntímagögnum í appinu
                        <Disclaimer type="Sub" />
                    </h6>
                    <CheckList
                        items={[
                            "Markaðsgögn í rauntíma.",
                            "Yfirlit yfir viðskipti.",
                            "Tilkynningar og rauntímavakt á hlutabréf að eigin vali.",
                        ]}
                        size="sm"
                    />
                    <div className="price-card__actions">
                        {/* SENDA NOTANDA  */}
                        <Button
                            buttonType="buy"
                            anchorProps={{
                                href: `/Hafa-samband/keldan-app`,
                            }}
                        >
                            Kaupa áskrift
                        </Button>
                    </div>
                </PriceCard>
            </SwiperSlide>
            <SwiperSlide>
                {/* THIRD PRICE CARD */}
                <PriceCard
                    variant="secondary"
                    type="Vefur og KODIAK Pro"
                    key="individual"
                    noPriceLabel
                    keldanMarkadirPriceCard
                >
                    <div className="price-card__price">
                        <p>
                            <strong>16.900</strong>kr. á mánuði + VSK
                        </p>
                    </div>
                    <span className="small-disclaimer">
                        Ásamt mánaðargjaldi Nasdaq OMX*
                    </span>
                    <h6 className="description" style={{ color: "white" }}>
                        Aðgangur að <a href="https://markadir.keldan.is">Keldan markaðir</a>{" "}
                        vef og <a href="https://www.kodi.is/product/kodiak-pro/">KODIAK Pro</a>{" "}
                        kerfinu ásamt rauntímagögnum eftir þörfum
                        <Disclaimer type="Sub" />
                    </h6>
                    <CheckList
                        items={[
                            "Markaðsgögn í rauntíma.",
                            "Yfirlit yfir viðskipti.",
                            "Tilkynningar og rauntímavakt.",
                            "Upplýsingar um öll verð í tilboðabókum hlutabréfa og skuldabréfa",
                        ]}
                        size="sm"
                    />
                    <div className="price-card__actions">
                        <Button
                            buttonType="primary"
                            anchorProps={{
                                href: `/Hafa-Samband/Keldan-Markadir-Prufa`,
                            }}
                        >
                            Fá prufuaðgang
                        </Button>
                        <Button
                            buttonType="buy"
                            anchorProps={{
                                href: `/Hafa-Samband/Keldan-Markadir`,
                            }}
                        >
                            Kaupa áskrift
                        </Button>
                    </div>
                </PriceCard>
                <div className="price-card-notice">
                    <p className="paragraph-small--italic">*Sjá verðskrá fyrir neðan.</p>
                </div>
            </SwiperSlide>
        </Slider>
    );
};

const Disclaimer: React.FC<{ type: "App" | "Sub" }> = ({ type }) => {
    return (
        <Tooltip
            noUnderline
            tooltip={
                type === "App" ? (
                    <span>
                        Þú sækir appið í App Store eða Google Play og velur áskriftina í
                        appinu sjálfu (in-app-purchase). Markaðsgögn sem fylgja þessari
                        áskrift er <br /> <strong>Leið 1: Hlutabréf og skuldabréf</strong> -
                        Hagstæðasta kaup- og sölutilboð.
                    </span>
                ) : (
                    <span>
                        Þú velur eina leið af þrem fyrir áskrift að rauntímagögnum frá
                        Nasdaq OMX.
                    </span>
                )
            }
            text={
                <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faCircleInfo} />
            }
            trigger="hover"
        />
    );
};

export default KeldanMarkadirPricingSection;