// Package imports:
import React from 'react';
import cx from 'classnames';
import { v4 as uuidv4 } from 'uuid';

export type AvatarNames = 'NASDAQ' | 'ARION' | 'ISB' | 'KVIKA' | 'KVIKAE' | 'MAREL' | 'EIM' | 'EIK' | 'FESTI' | 'HAGA' | 'ICESEA' | 'NOVA' | 'OLGERD' | 'ORIGO' | 'REITIR'
 | 'SIMINN' | 'SJOVA' | 'VIS' | 'SKEL' | 'REGINN' | 'SVN' | 'SYN' | 'AKTA' | 'IV' | 'IS' | 'STEFNIR' | 'LANDSBREF' | 'GAMMA' | 'FRJALSI'
 | 'LAIS' | 'ICEAIR' | 'OMX' | 'HAMP' | 'KALD' | 'KLAPP B' | 'PLAY' | 'SFS B' | 'SOLID' | 'OSSR' | 'AMRQ';

type AvatarSizes = 'sm' | 'md' | 'lg';

interface IProps {
    name?: AvatarNames,
    size: AvatarSizes,
    color?: boolean,
    backgroundGrey?: boolean,
    dropShadow?: boolean,
    dataLink?: string
};

const Avatar: React.FC<IProps> = ({
    name,
    size,
    color,
    backgroundGrey,
    dataLink
}) => {
    const getAvatar = () => {
        if (dataLink) {
            return (
                <object style={{maxWidth: '90%'}}  type="image/png" name={uuidv4()} data={dataLink}> </object>
            );
        }
        if (!name) return null;
        if (color) {
            return (
                <img
                    src={`https://cdn.livemarketdata.com/Avatars/${name}-color.svg`}
                    alt={name}
                    width="80"
                    height="80"
                    onError={(event) => {
                        event.currentTarget.className = "missing-avatar";
                    }}
                />
            );
        }
        return (
            <img
                src={`https://cdn.livemarketdata.com/Avatars/${name}.svg`}
                alt={name}
                width="80"
                height="80"
                onError={(event) => {
                    event.currentTarget.className = "missing-avatar";
                }}
            />
        );
    }
    return (
        <div className={cx(
            'KCL_avatar',
            size,
            {'grey': backgroundGrey},
            name
        )}>
            {getAvatar()}
        </div>
    );
}
export default Avatar;