// Package imports:
import React, { useMemo, useRef, useState } from "react";
import Bugsnag from "@bugsnag/js";
import { Form, Formik } from "formik";
import * as Yup from "yup";
// Component imports:
import Alert from "../../../ui-elements/Alert/Alert";
import Button from "../../../ui-elements/Button/Button";
import Input from "../../../ui-elements/Input/Formik/Input";
import Link from "../../../ui-elements/Link/Link";
import Table from "../../../ui-elements/Table/Table";
import DisplayBox from "../../../ui-elements/DisplayBox/DisplayBox";
import LockIcon from "../../../ui-elements/LockIcon/LockIcon";
import ExampleReportButton from "../../ExampleReportButton/ExampleReportButton";
import Tooltip from "../../../ui-elements/Tooltip/Tooltip";
import SearchResults from "../../../ui-elements/SearchResults/SearchResults";
// Service imports:
import { GET_KELDAN_API_URL } from "../../../services/config";
import { useBuyingProcess } from "../../../services/buyhook";
import { ErrorMessages } from "../../../services/errorMessages";
// Type imports:
import { IKeldanApiResponse } from "../../../types/KeldanTypes";
import { IPropertySearchResult } from "../../../types/PropertyTypes";
import { IisAuthenticated } from "../../../types/Types";
import { useHistoryRefresh } from "../../../contexts/HistoryRefreshContext";

interface IBuyGangverd {
  whereClick: "form" | "list";
  fastanr: string;
}

const GangverdSearch: React.FC<IisAuthenticated> = ({ isAuthenticated }) => {
  const { triggerRefresh } = useHistoryRefresh();
  const [isSearchFormLoading, setIsSearchFormLoading] = useState(false);
  const [searchButtonLoading, setSearchButtonLoading] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<IPropertySearchResult>();

  const searchResultsRef = useRef<HTMLDivElement | null>(null);

  const defaultSearchTerm = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const fastanr = urlParams.get("fastanr");
    return fastanr === null || fastanr === "" ? null : fastanr;
  }, []);

  const { tryPurchaseItem, tryToBuyItem, resetState, setToastError, ModalsAndToasts } = useBuyingProcess(
    async (values: IBuyGangverd, modalReference: string) => {
      try {
        const { fastanr } = values;
        const url = `${GET_KELDAN_API_URL()}/Fasteignir/Gangverd-Kaupa`;
        const query = new URLSearchParams({
          fastanr: fastanr,
          reference: modalReference,
        });
        const response = await fetch(`${url}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
          body: query,
        });

        if (response.ok) {
          if (response.redirected) {
            window.location.href = response.url;
            return;
          }
          triggerRefresh();
          const responseBody: IKeldanApiResponse<IPropertySearchResult> = await response.json();
          const { message } = responseBody;
          if (message === ErrorMessages.NoCard) {
            resetState("no card");
          } else if (message)
            resetState({
              type: "alert",
              headText: message,
            });
          else resetState("bad request");
        } else {
          resetState("response not ok");
        }
      } catch (e) {
        resetState("network error");
      }
    },
    "Verðvísi Gangverðs"
  );

  const handleSearch = async (searchTerm: string, whereClick: "list" | "form") => {
    try {
      const requestQuery = new URLSearchParams({
        term: searchTerm,
      });
      const url =
        whereClick === "form"
          ? `${GET_KELDAN_API_URL()}/Property/PropertySearchAll`
          : `${GET_KELDAN_API_URL()}/Property/GangverdSearch`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: requestQuery,
      });
      // Done after the request for better user experience.
      setSearchTerm(searchTerm);
      if (response.ok) {
        if (response.redirected) {
          window.location.href = response.url;
        } else {
          const responseBody: IKeldanApiResponse<IPropertySearchResult> = await response.json();
          const { message, result } = responseBody;
          if (result) {
            setSearchResults(responseBody.result);
            searchResultsRef.current?.scrollIntoView();
          } else if (message === ErrorMessages.NoCard) {
            setToastError("no card");
          } else if (message) {
            setToastError({
              type: "alert",
              headText: message,
            });
          } else {
            setToastError("bad request");
          }
        }
      } else {
        setToastError("response not ok");
      }
    } catch (e) {
      if (e instanceof Error) Bugsnag.notify(e);
      setToastError("network error");
    }
    // Reset all loading states.
    setSearchButtonLoading(null);
    setIsSearchFormLoading(false);
  };

  const displayForms = (status: "enabled" | "disabled") => {
    return (
      <div>
        <Formik
          initialValues={{
            fastanr: defaultSearchTerm ?? "",
            tilvisun: "",
          }}
          validationSchema={Yup.object({
            fastanr: Yup.string().required("Fastanúmer má ekki vera tómt"),
            tilvisun: Yup.string(),
          })}
          onSubmit={({ fastanr, tilvisun }) =>
            tryPurchaseItem({ fastanr, whereClick: "form" }, "confirm-modal-with-reference", tilvisun)
          }
          component={(props: { values: IPurchasingFormValues }) => (
            <PurchasingSubFormComponent
              values={props.values}
              loading={tryToBuyItem !== null && tryToBuyItem.whereClick === "form"}
              disabled={status === "disabled"}
            />
          )}
        />
        <p className="paragraph">Einnig er hægt að leita eftir fasteign</p>
        <Formik
          initialValues={{
            searchterm: "",
          }}
          validationSchema={Yup.object({
            searchterm: Yup.string().required("Leit má ekki vera tóm"),
          })}
          onSubmit={(values) => {
            setIsSearchFormLoading(true);
            handleSearch(values.searchterm, "form");
          }}
          component={(props: { values: ISearchFormValues }) => (
            <SearchSubFormComponent
              loading={isSearchFormLoading}
              values={props.values}
              disabled={status === "disabled"}
            />
          )}
        />
      </div>
    );
  };

  const displayResults = () => {
    if (searchResults?.response?.properties?.properties) {
      return (
        <DisplayBox>
          <Table
            tableSize="lg"
            data={searchResults.response.properties.properties}
            columns={[
              {
                title: "Fastanúmer",
                renderCell: ({ property_number }) => property_number,
                textAlign: "left",
              },
              {
                title: "Heiti",
                renderCell: () => searchResults.response?.properties?.address?.street,
                textAlign: "left",
              },
              {
                title: "Húsnúmer",
                renderCell: () => searchResults.response?.properties?.address?.house_number,
                textAlign: "left",
              },
              {
                title: "Merking",
                renderCell: ({ units }) => units[0].tag,
                textAlign: "left",
              },
              {
                title: "Sveitarfélag",
                renderCell: () => searchResults.response?.properties?.address?.county,
                textAlign: "left",
              },
              {
                title: "Byggð",
                renderCell: () => searchResults.response?.properties?.address?.city,
                textAlign: "left",
              },
              {
                title: "",
                renderCell: ({ property_number }) => (
                  <Button
                    buttonType="buy"
                    size="sm"
                    showLoader={
                      tryToBuyItem !== null &&
                      tryToBuyItem.fastanr === property_number &&
                      tryToBuyItem.whereClick === "list"
                    }
                    onClick={() => {
                      if (property_number === undefined) {
                        setToastError({
                          type: "alert",
                          headText: "Villa í fastanúmeri",
                        });
                      } else {
                        tryPurchaseItem(
                          {
                            fastanr: property_number,
                            whereClick: "list",
                          },
                          "confirm-modal-with-reference"
                        );
                      }
                    }}
                  >
                    Kaupa
                  </Button>
                ),
              },
            ]}
          />
        </DisplayBox>
      );
    }
    if (searchResults?.response?.addresses) {
      return (
        <DisplayBox>
          <Table
            tableSize="lg"
            data={searchResults.response.addresses}
            columns={[
              {
                title: "Heitisnúmer",
                renderCell: ({ address: { name_number } }) => name_number,
                textAlign: "left",
              },
              {
                title: "Heiti",
                renderCell: ({ address: { street } }) => street,
                textAlign: "left",
              },
              {
                title: "Húsnúmer",
                renderCell: ({ address: { house_number } }) => house_number,
                textAlign: "left",
              },
              {
                title: "Sveitarfélag",
                renderCell: ({ address: { county } }) => county,
                textAlign: "left",
              },
              {
                title: "Byggð",
                renderCell: ({ address: { city } }) => city,
                textAlign: "left",
              },
              {
                title: "",
                renderCell: ({ address: { name_number } }) => (
                  <Button
                    buttonType="secondary"
                    size="sm"
                    showLoader={searchButtonLoading !== null && searchButtonLoading === name_number}
                    onClick={() => {
                      if (name_number === undefined) {
                        setToastError({
                          type: "alert",
                          headText: "Villa í heitisnúmeri",
                        });
                      } else {
                        setSearchButtonLoading(name_number);
                        handleSearch(name_number, "list");
                      }
                    }}
                  >
                    Skoða
                  </Button>
                ),
              },
            ]}
          />
        </DisplayBox>
      );
    }
    return <Alert type="info" headText="Engar niðurstöður fundust" />;
  };

  return (
    <div className="KCL_property-search-form">
      <div className="shell">
        <h3>
          {!isAuthenticated && <LockIcon size="lg" />}
          Verðvísir Gangverðs
        </h3>
        <div className="property-form-description">
          <p className="paragraph">
            Gangverð hefur þróað vöruna Verðvísi fyrir aðila í eignaviðskiptum og fjármögnun sem gefur betri upplýsingar
            en áður hafa verið aðgengilegar um verð og verðþróun fasteigna. Verðvísirinn er uppfærðu mánaðarlega til
            samræmis við þróun eignamarkaða þann mánuðinn.
          </p>
          <p className="paragraph--bold">
            {" "}
            Hér er hægt að kaupa upplýsingar úr Verðvísinum fyrir eina ákveðna fasteign.{" "}
            <Link linkSize="18" href="/Verdskra#Gagnaleit">
              Sjá verðskrá.
            </Link>
          </p>
        </div>
        {isAuthenticated ? (
          displayForms("enabled")
        ) : (
          <Tooltip
            tooltip={<span>Þú þarft að skrá þig inn og vera með skráð kreditkort til að nýta þessa þjónustu.</span>}
            text={displayForms("disabled")}
          />
        )}
        <ExampleReportButton size="lg" reportType="gangverd" />
      </div>
      <SearchResults
        title={`Leitarniðurstöður fyrir ${searchTerm}`}
        show={searchResults !== undefined}
        closeResults={() => setSearchResults(undefined)}
        ref={searchResultsRef}
        component={displayResults()}
      />
      <ModalsAndToasts />
    </div>
  );
};

export default GangverdSearch;

interface ISearchFormValues {
  searchterm: string;
}

interface ISearchSubFormProps {
  values: ISearchFormValues;
  loading: boolean;
  disabled: boolean;
}

const SearchSubFormComponent: React.FC<ISearchSubFormProps> = ({ values, loading, disabled }) => {
  return (
    <Form>
      <div className="form__body">
        <div className="form__section">
          <div className="form__row">
            <div className="form__col">
              <Input
                placeholder="Leita eftir götuheiti, fastanúmeri eða heitisnúmeri"
                name="searchterm"
                id="searchterm"
                value={values.searchterm}
                disabled={disabled}
              />
            </div>
            <div className="form__col actions">
              <div className="form__actions">
                <Button showLoader={loading} size="lg" disabled={disabled}>
                  Leita
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

interface IPurchasingFormValues {
  fastanr: string;
  tilvisun: string;
}

interface IPurchasingSubFormProps {
  values: IPurchasingFormValues;
  loading: boolean;
  disabled: boolean;
}

const PurchasingSubFormComponent: React.FC<IPurchasingSubFormProps> = ({ values, loading, disabled }) => {
  return (
    <Form>
      <div className="form__body">
        <div className="form__section">
          <div className="form__row">
            <div className="form__col">
              <Input placeholder="Fastanúmer" name="fastanr" id="fastanr" value={values.fastanr} disabled={disabled} />
            </div>
            <div className="form__col">
              <Input
                placeholder="Tilvísun á reikning"
                name="tilvisun"
                id="tilvisun"
                value={values.tilvisun}
                disabled={disabled}
              />
            </div>
            <div className="form__col actions">
              <div className="form__actions">
                <Button showLoader={loading} buttonType="buy" size="lg" disabled={disabled}>
                  Kaupa
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};
